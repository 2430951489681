import { Middleware } from '@reduxjs/toolkit';
import {
  startChat,
  sendMessage, endChat,
  sendTypingMessage
} from './slices/trafficSlice';
import { getWebSocketService } from './WebSocketServiceSingleton';

const webSocketMiddleware: Middleware = store => next => action => {




  if (startChat.match(action)) 
  {
    const { customerId, message, ownerToken, messageId } = action.payload;
    const chatPayload = {
      action: 'start_chat',
      payload: {
        customer_id: customerId,
        message: message,
        owner_token: ownerToken,
        messageId: messageId
      }
    };


    const wsService = getWebSocketService(); // Access the singleton instance

    const serializedPayload = JSON.stringify(chatPayload);
    wsService.sendMessage(serializedPayload);
  }
  else if (endChat.match(action)) 
    {
      const { messageId, customerId, message, ownerToken } = action.payload;
  

  
      const chatPayloadMessage = {
        action: 'agent_ending_chat_to_socket',
        payload: {
          messageId: messageId,
          type: 'agent',
          cid: customerId,
          message: message
        },
        owner_token: ownerToken
      };

      console.log("sending this to the socket ", chatPayloadMessage)
  
      const wsService = getWebSocketService(); // Access the singleton instance
      const serializedPayload = JSON.stringify(chatPayloadMessage);
  
      wsService.sendMessage(serializedPayload);
    }  
  else if (sendMessage.match(action)) 
  {
    const { messageId, customerId, message, ownerToken } = action.payload;


    const chatPayloadMessage = {
      action: 'agent_sending_message',
      payload: {
        messageId: messageId,
        type: 'agent',
        cid: customerId,
        message: message
      },
      owner_token: ownerToken
    };

    const wsService = getWebSocketService(); // Access the singleton instance
    const serializedPayload = JSON.stringify(chatPayloadMessage);

    wsService.sendMessage(serializedPayload);
  } 
  else if (sendTypingMessage.match(action)) 
  {
    const { customerId, ownerToken } = action.payload;

    console.log(action.payload);

    const chatPayloadMessage = {
      action: 'agent_typing_message',
      payload: {
        type: 'agent',
        cid: customerId
      },
      owner_token: ownerToken
    };

    const wsService = getWebSocketService();
    const serializedPayload = JSON.stringify(chatPayloadMessage);

    wsService.sendMessage(serializedPayload);
  }

  return next(action);
};

export default webSocketMiddleware;
