import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'hooks';

interface ProtectedRouteProps {
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { token, isMembershipValid } = useAppSelector(state => state.auth);

  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  if (isMembershipValid === false) {
    // If authenticated but membership is not valid, redirect to renew membership page
    return <Navigate to="/renew-membership" />;
  }

  if (isMembershipValid === null) {
    // Optionally show a loading indicator if membership status is still being checked
    return <div>Checking membership status...</div>;
  }

  // If authenticated and membership is valid, render the children or the Outlet (for nested routes)
  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
