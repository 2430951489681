import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';

import { store, persistor } from 'store';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import WebSocketManager from 'components/WebsocketManager';

import { WebSocketProvider } from 'providers/WebsocketContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <WebSocketProvider>
        <AppProvider>
          <SettingsPanelProvider>
            <ChatWidgetProvider>
              <BreakpointsProvider>
                <RouterProvider router={router} />
              </BreakpointsProvider>
            </ChatWidgetProvider>
          </SettingsPanelProvider>
        </AppProvider>
      </WebSocketProvider>
    </PersistGate>
  </Provider>
);
