import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Form, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/72x72/57.webp';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logout } from 'store/slices/authSlice';
import { useAppSelector } from 'hooks';

import { setAcceptChats } from 'store/slices/authSlice';
import { getWebSocketService } from 'store/WebSocketServiceSingleton';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, isMembershipValid, acceptChats } = useAppSelector(state => state.auth);


  const changeStatus = (currentStatus:Boolean) =>
  {
    dispatch(setAcceptChats(!currentStatus));

    var sendMessageObj = {action: "update_accept_status", payload: {
      accept_chats: currentStatus ? "no" : "yes",
      owner_token: token
    }}

    const wsService = getWebSocketService()
    wsService.sendMessage(JSON.stringify(sendMessageObj));


  }


  const handleLogout = () => {
    dispatch(logout());
    navigate('/login'); // Redirect to login page after logout
  };

  const [navItems] = useState([
    {
      label: 'Profile',
      icon: 'user'
    },
    {
      label: 'Dashboard',
      icon: 'pie-chart'
    },
    {
      label: 'Posts & Activity',
      icon: 'lock'
    },
    {
      label: 'Settings & Privacy ',
      icon: 'settings'
    },
    {
      label: 'Help Center',
      icon: 'help-circle'
    },
    {
      label: 'Language',
      icon: 'globe'
    }
  ]);
  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={avatar} size="xl" />
            <h6 className="text-body-emphasis">Jerry Seinfield</h6>
          </div>

          {isMembershipValid == true ? (
            <>
              <div className="mb-3 mx-3">
                <Form.Control
                  type="text"
                  placeholder="Update your status"
                  size="sm"
                />
              </div>
              <div style={{ height: '10rem' }}>
                <Scrollbar>
                  <Nav className="nav flex-column mb-2 pb-1">
                    {navItems.map(item => (
                      <Nav.Item key={item.label}>
                        <Nav.Link href="#!" className="px-3">
                          <FeatherIcon
                            icon={item.icon}
                            size={16}
                            className="me-2 text-body"
                          />
                          <span className="text-body-highlight">
                            {item.label}
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Scrollbar>
              </div>{' '}
            </>
          ) : null}
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          {isMembershipValid == true ? (
            <>
              <Nav className="nav flex-column my-3">
              <Nav.Item>
                  <Nav.Link href="#!" className="px-3">
                    <FeatherIcon
                      icon="user-plus"
                      size={16}
                      className="me-2 text-body"
                    />
                    <span>Add another account</span>
                  </Nav.Link>
                </Nav.Item>



                <Nav.Item>
                  <Nav.Link href="#!" onClick={() =>{
                    changeStatus(acceptChats);

                  }} className="px-3">
                    <FeatherIcon
                      icon="target"
                      size={16}
                      className={`me-2 text-body ${  !acceptChats ? "bg-danger text-danger" : "bg-success text-success" } `}
                    />
                    <span>Change Online Status</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              
              <hr />{' '}
            </>
          ) : (
            <p></p>
          )}

          <div className="px-3">
            <Link
              to="#"
              onClick={() => {
                handleLogout();
              }}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Link>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#!">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
