import { CardBody, Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Card } from 'react-bootstrap';

import PositionSettings from './PositionSettings';


const AspectDesign = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );
 

  const {
    config: { isDark }
  } = useAppContext();

 

  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto" className='mb-5'>
          <h2 className="mb-2 text-body-emphasis">Aspect and Design</h2>
          <h5 className="text-body-tertiary fw-semibold">
           View and change your chat settings, and the way your customers interact with you.
          </h5>
        </Col>



        <Col md={12} xs="auto" className='mb-2'>
<h5>1. Position of Chat Widget</h5>


<Card>
    <CardBody>


        <PositionSettings></PositionSettings>

    </CardBody>
</Card>
</Col>




<Col md={12} xs="auto" className='mb-2'>
<h5>2. Colors</h5>


<Card>
    <CardBody>


    </CardBody>
</Card>
</Col>



<Col md={12} xs="auto" className='mb-2'>
<h5>3. Additional Tweaks</h5>


<Card>
    <CardBody>


    </CardBody>
</Card>
</Col>


      </Row>
    </>
  );
};

export default AspectDesign;
