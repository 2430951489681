// src/store/slices/authSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom'; // To handle navigation after successful login
import { RootState } from 'store';

interface AuthState {
  token: string | null;
  user: object | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isMembershipValid: boolean | null; // Add this line
  acceptChats: boolean;
}

const initialState: AuthState = {
  token: localStorage.getItem('authToken')
    ? localStorage.getItem('authToken')!
    : null,
  user: null,
  status: 'idle',
  error: null,
  isMembershipValid: false,
  acceptChats: true
};

export const login = createAsyncThunk(
  'auth/login',
  async (
    { username, password }: { username: string; password: string },
    thunkAPI
  ) => {
    try {
      // Set processing state before making the request
      thunkAPI.dispatch(setStatus('loading'));

      const response = await fetch(
        'https://easychatdesk.com/wp-json/api/v1/executeLogin',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: username, password: password })
        }
      );

      const MYJSON = await response.json();

      console.log(MYJSON);

      if (MYJSON.response == 200) {
        localStorage.setItem('authToken', JSON.stringify(MYJSON.token));

        // Optional: Save user data or token in the Redux state if needed
        return MYJSON.token; // This can include token and user data
      } else {
        // Reject the thunk with an error message
        return thunkAPI.rejectWithValue(MYJSON.reason);
      }
    } catch (error) {
      // Catch any unexpected errors and reject the thunk
      return thunkAPI.rejectWithValue('Unexpected error occurred during login');
    }
  }
);

export const checkMembership = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>('auth/checkMembership', async (_, thunkAPI) => {
  try {
    // Assume you have an endpoint to check membership status
    const token = thunkAPI.getState().auth.token; // Assuming token is required for this check
    const response = await fetch(
      'https://api10.easychatdesk.com/check-membership/' + token,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const data = await response.json();

    if (data.isValid) {
      return true;
    } else {
      return thunkAPI.rejectWithValue('Membership not valid');
    }
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to check membership');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      console.log('Logging out'); // Debug log
      state.token = null;
      state.user = null;
      state.status = 'idle';
      state.error = null;
      localStorage.removeItem('authToken');
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setAcceptChats: (state, action) => {
      state.acceptChats = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        console.log('Login pending'); // Debug log
        state.status = 'loading';
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log('Login successful', action.payload); // Debug log

        state.token = action.payload.token;
        state.user = action.payload.user;
        state.status = 'succeeded';
        state.error = null;
        localStorage.setItem('authToken', action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        console.log('Login failed', action.payload); // Debug log
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(checkMembership.fulfilled, (state, action) => {
        state.isMembershipValid = action.payload as boolean;
      })
      .addCase(checkMembership.rejected, (state, action) => {
        state.isMembershipValid = false;
        console.log('Membership check failed', action.payload);
      });
  }
});

export const { logout, setStatus, setAcceptChats } = authSlice.actions;
export default authSlice.reducer;
