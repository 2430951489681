import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import EmailLayout from 'layouts/EmailLayout';
import BulkSelectProvider from 'providers/BulkSelectProvider';
import InboxToolbar from 'components/modules/email/InboxToolbar';
import EmailRow from 'components/modules/email/EmailRow';
import { emails } from 'data/email';
import { useEffect } from 'react';
import axios from 'axios';
import { Outlet } from 'react-router-dom';

import { Table, Pagination } from 'react-bootstrap';

import { Ticket } from 'data/Ticket';
interface ApiResponse {
  page: number;
  limit: number;
  total_tickets: number;
  tickets: Ticket[];
}


const TicketsPage = () => {


  const token = useSelector((state: RootState) => state.auth.token);


  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(8);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [order, setOrder] = useState<string>('desc');
  const [orderType, setOrderType] = useState<string>('date');

  const fetchTickets = async (page: number) => {
    try {
      const response = await axios.get<ApiResponse>(`https://api10.easychatdesk.com/get-offline-tickets/${token}`, {
        params: {
          page: page,
          limit: itemsPerPage,
          order: order,
          orderType: orderType,
        },
      });
      setTickets(response.data.tickets);
      setTotalItems(response.data.total_tickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  // Fetch tickets when the component mounts or when currentPage/order/orderType changes
  useEffect(() => {
    fetchTickets(currentPage);
  }, [currentPage, order, orderType]);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const {
    config: { isDark }
  } = useAppContext();



  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto">
          <h2 className="mb-2 text-body-emphasis">Tickets</h2>
          <h5 className="text-body-tertiary fw-semibold">
           See previous tickets and reply to your customers with offline ticket replies.
          </h5>
        </Col>
      </Row>


 
      <div className="mb-3">
        <label>Order: </label>
        <select value={order} onChange={(e) => setOrder(e.target.value)} className="ml-2">
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
        <label className="ml-3">Order Type: </label>
        <select value={orderType} onChange={(e) => setOrderType(e.target.value)} className="ml-2">
          <option value="date">Date</option>
          {/* Add other options for orderType if needed */}
        </select>
      </div>

 
 
          {tickets.length > 0 ? (
            tickets.map((ticket) => (
              
              <EmailRow index={ticket.id} ticket={ticket}></EmailRow>


            ))
          ) : 
        null}
   
 
 
      <Pagination className="justify-content-center">
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
        
 
    </>
  );
};

export default TicketsPage;
