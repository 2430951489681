import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import { useAppContext } from 'providers/AppProvider';
import { useAppSelector } from 'hooks';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import RenewMembership from './RenewMembership';

const RenewMembershipOuter = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();

  const { isMembershipValid } = useAppSelector(state => state.auth);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Initially set to true
  const [showContent, setShowContent] = useState(false); // Controls the display of content

  useEffect(() => {
    // Set a timeout to change the state after 1 second
    const timeoutId = setTimeout(() => {
      setShowContent(true);
    }, 1000);

    // Redirect if membership is valid
    if (isMembershipValid) {
      navigate('/'); // Redirect to '/' when membership is valid
      return; // Prevent setting showContent state if redirecting
    }

    // Cleanup function to clear timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isMembershipValid, navigate]);

  if (loading) {
    return null; // Render nothing initially
  }

  if (!showContent) {
    return null; // Render nothing until 1 second has passed
  }

  return (
    <Container fluid className="px-0">
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') &&
        (isMembershipValid === true ? <NavbarVertical /> : null)}
      {navbarPosition === 'vertical' && <NavbarTopDefault />}
      {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
        <NavbarTopHorizontal />
      )}
      {navbarPosition === 'dual' && <NavbarDual />}

      <div className={classNames('content')}>
        <RenewMembership />
        <Footer className="position-absolute" />
      </div>
    </Container>
  );
};

export default RenewMembershipOuter;
