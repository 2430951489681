import React, { ReactNode } from 'react';
import { Accordion, useAccordionButton } from 'react-bootstrap';
import './Accordion.css'; // Importing custom CSS
import { Link } from 'react-router-dom';

// Define the props for CustomToggle
interface CustomToggleProps {
  children: ReactNode;
  eventKey: string;
}

const CustomToggle: React.FC<CustomToggleProps> = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <div className="d-flex align-items-center" onClick={decoratedOnClick}>
      <span className="arrow me-2"></span>
      {children}
    </div>
  );
};

const VerticalAccordionMenu: React.FC = () => {
  return (
    <Accordion defaultActiveKey="0" className="w-100">
      {/* Main Item 1 */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <CustomToggle eventKey="0">Channels</CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            <li><Link to="/settings">Install EasyChatDesk Widget</Link></li>
            <li><a href="#subitem1-2">Facebook</a></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <CustomToggle eventKey="2">Chat page</CustomToggle>
        </Accordion.Header>
      </Accordion.Item>

 
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <CustomToggle eventKey="3">Website Widget</CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            <li><Link to="/settings/aspect-design">Aspect & Design</Link></li>
            <li><Link to="/settings/language">Language</Link></li>
            <li><Link to="#subitem2-3">Availability</Link></li>
            <li><Link to="#subitem2-3">Welcome procedure</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>



      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <CustomToggle eventKey="5">Custom Forms</CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            <li><a href="#subitem2-1">Pre-chat Form</a></li>
            <li><a href="#subitem2-2">Automated Messages</a></li>
            <li><a href="#subitem2-3">Post-chat Feedback</a></li>
            <li><a href="#subitem2-3">Create Ticket Form</a></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>

 
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <CustomToggle eventKey="4">Start Chat Options</CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            <li><a href="#subitem3-1">Subitem 3.1</a></li>
            <li><a href="#subitem3-2">Subitem 3.2</a></li>
            <li><a href="#subitem3-3">Subitem 3.3</a></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>



      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <CustomToggle eventKey="5">AI Replies</CustomToggle>
        </Accordion.Header>
      </Accordion.Item>


      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <CustomToggle eventKey="6">Predefined Responses</CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            <li><a href="#subitem3-1">Subitem 3.1</a></li>
            <li><a href="#subitem3-2">Subitem 3.2</a></li>
            <li><a href="#subitem3-3">Subitem 3.3</a></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>



      <Accordion.Item eventKey="7">
        <Accordion.Header>
          <CustomToggle eventKey="7">Chat Settings</CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            <li><a href="#subitem3-1">Routing</a></li>
            <li><a href="#subitem3-2">Timeouts</a></li>
            <li><a href="#subitem3-3">Other Settings</a></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>




      <Accordion.Item eventKey="8">
        <Accordion.Header>
          <CustomToggle eventKey="8">Security</CustomToggle>
        </Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            <li><a href="#subitem3-1">Banned Customers</a></li>
            <li><a href="#subitem3-2">Trusted Domains</a></li>
            
          </ul>
        </Accordion.Body>
      </Accordion.Item>



    </Accordion>
  );
};

export default VerticalAccordionMenu;
