import { useChatContext } from 'providers/ChatProvider';

const ConversationStarter = () => {
  const { currentConversation } = useChatContext();

  return (
    <div className="d-flex align-items-end justify-content-center text-center h-100">
      <div>
        This is the beginning of your private chat with your customer. Write a
        message to greet them.
      </div>
    </div>
  );
};

export default ConversationStarter;
