import { conversations } from 'data/chat';
import ChatProvider from 'providers/ChatProvider';
import { Outlet } from 'react-router-dom';

const Tickets = () => {
  return (

      <div className="">
        <Outlet />
      </div>

  );
};

export default Tickets;
