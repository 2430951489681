import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { Accordion, Card, Button } from 'react-bootstrap';

import VerticalAccordionMenu from './AccordionMenu';
import { Outlet } from 'react-router-dom';

const SettingsPage = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );

  console.log(trafficInformation);

  const {
    config: { isDark }
  } = useAppContext();

  const handleRowClick = (visitor: any) => {
    setSelectedVisitor(visitor);
    setIsSidePanelOpen(true);
  };

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
    setSelectedVisitor(null);
  };

  return (
    <>
      <Row className=" h-100 mb-6 justify-content-between"  >
       <Col className='col-auto' >     


<VerticalAccordionMenu></VerticalAccordionMenu>

      </Col>

      <Col   className='h-100'>   

<Outlet/>

      </Col>

      </Row>
    </>
  );
};

export default SettingsPage;
