
import { Col, Row } from 'react-bootstrap';



import { useAppContext } from 'providers/AppProvider';
import SearchBox from 'components/common/SearchBox';

import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent } from 'react';
import { VisitorsTable } from 'components/tables/VisitorsTable';
import { useState } from 'react';

import SidePanel from './SidePanel';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const TrafficPage = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );


  
  const [customerTypes, setcustomerTypes] = useState("all_customers")


  const {
    config: { isDark }
  } = useAppContext();

  const handleRowClick = (visitor: any) => {

    console.log("setSelectedVisitor: " , visitor);

    setSelectedVisitor(visitor);
    setIsSidePanelOpen(true);
  };

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
    setSelectedVisitor(null);
  };

  function displayCustomerName(itemName: any) {
    if (itemName == null) return '';
    let name = 'Unnamed Customer';
    if (itemName.name != undefined) name = itemName.name;
    if (itemName.name == '') name = 'Unnamed Customer';

    return name;
  }

  const getCountryImageSRC = (country: any) => {
    if (country != undefined) {
      const country2 = country.toLowerCase();
      return 'https://cdn.easychatdesk.com/flags/' + country2 + '.svg';
    }

    return '';
  };

  function displayCurrentUserHistory(cuser: any) {
    if (cuser != null) {
      if (cuser.history == null) return null;

      if (cuser.history.length === 0) return <li>No visits</li>;

      // Reverse the history array before mapping
      return cuser.history
        .slice()
        .reverse()
        .map((historyItem: any) => {
          return (
            <li className="mb-1" key={historyItem.visited_page}>
              <a
                href={historyItem.visited_page}
                title={historyItem.visited_page}
                target="_blank"
                rel="noopener noreferrer"
              >
                {historyItem.title.substring(0, 43)}...
              </a>
            </li>
          );
        });
    } else {
      return <li>No visits</li>;
    }
  }

  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto">
          <h2 className="mb-2 text-body-emphasis">Traffic</h2>
          <h5 className="text-body-tertiary fw-semibold">
            See who is online right now on your website
          </h5>
        </Col>


        <Col  md={12}>

        <ul className="nav nav-pills">
  <li className="nav-item">
    <a onClick={() =>{ setcustomerTypes('all_customers'); }} className={`nav-link ${customerTypes === 'all_customers' ? 'active' : ''}`}  aria-current="page" href="#">All Customers</a>
  </li>

  <li className="nav-item">
    <a onClick={() =>{ setcustomerTypes('active'); }} className={`nav-link ${customerTypes === 'active' ? 'active' : ''}`} href="#">Active</a>
  </li>

  <li className="nav-item">
    <a onClick={() =>{ setcustomerTypes('inactive'); }} className={`nav-link ${customerTypes === 'inactive' ? 'active' : ''}`} href="#">Inactive</a>
  </li>

  <li className="nav-item">
    <a onClick={() =>{ setcustomerTypes('chatting'); }} className={`nav-link ${customerTypes === 'chatting' ? 'active' : ''}`} href="#">Chatting</a>
  </li>
  <li className="nav-item">
    <a onClick={() =>{ setcustomerTypes('waiting'); }} className={`nav-link ${customerTypes === 'waiting' ? 'active' : ''}`} href="#">Waiting for Reply</a>
  </li>


  <li className="nav-item">
    <a onClick={() =>{ setcustomerTypes('invited'); }} className={`nav-link ${customerTypes === 'invited' ? 'active' : ''}`} href="#">Invited</a>
  </li>


  <li className="nav-item">
    <a  onClick={() =>{ setcustomerTypes('browsing'); }}className={`nav-link ${customerTypes === 'browsing' ? 'active' : ''}`} href="#">Just Browsing</a>
  </li>
 
</ul>

        </Col>


        <Col md={12} xs="auto">
          <VisitorsTable
            onRowClick={handleRowClick}
            trafficInformation={trafficInformation}
            customerTypes={customerTypes}
          />
        </Col>
      </Row>

      <SidePanel
        show={isSidePanelOpen}
        onClose={closeSidePanel}
        title="Visitor Details"
      >
        <>
          <div className="w-100 mb-4 mt-2 text-center">
            <div className="circle-avatar-1 text-center">UC</div>
          </div>

          <h4 className="text-center mb-0">
            {displayCustomerName(selectedVisitor)}
          </h4>

          <p className="text-center  mb-0">
            {selectedVisitor?.region}, {selectedVisitor?.city}
          </p>
          <p className="text-center">{selectedVisitor?.country_name}</p>

          <div className="text-center">
            <img
              src={getCountryImageSRC(selectedVisitor?.country)}
              width="28"
            />
          </div>

          <div className={isDark ? 'line-line-1-dark' : 'line-line-1'}></div>

          <div className="w-100">
            <h5 className="card-user-1-title mb-4 mt-3">Visited Pages</h5>
            <div className="">
              <ul className="no-padding-no-margin">
                {displayCurrentUserHistory(selectedVisitor)}
              </ul>
            </div>
          </div>

          <div className={isDark ? 'line-line-1-dark' : 'line-line-1'}></div>

          <div className="w-100">
            <h5 className="card-user-1-title mb-4 mt-3">
              Additional Information
            </h5>
            <div className="w-100">
              <p className="mb-1">Chat Time: 1 minute</p>
              <p className="mb-1">First time visitor: 1 visit</p>
              <p className="mb-1">Chats: no chats</p>
              <p className="mb-1">
                Referral: <b>{selectedVisitor?.refer}</b>
              </p>
              <p className="mb-1">
                Current Page: <b>{selectedVisitor?.title}</b>
              </p>
            </div>
          </div>

          <div className={isDark ? 'line-line-1-dark' : 'line-line-1'}></div>

          <div className="w-100">
            <h5 className="card-user-1-title mb-4 mt-3">
              More Customer Information
            </h5>
            <div className="w-100">
              <p className="mb-1">IP: {selectedVisitor?.ip}</p>
              <p className="mb-1">Browser: {selectedVisitor?.browser}</p>
              <p className="mb-1">Device: Computer</p>
              <p className="mb-1">OS: {selectedVisitor?.os}</p>
            </div>
          </div>
        </>
      </SidePanel>
    </>
  );
};

export default TrafficPage;
