 

import { Row, Col, Form, Table, Button, Card } from 'react-bootstrap';

import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

 

const AgentsPage = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');


  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState<string | null>(null);

  const token = useSelector((state: RootState) => state.auth.token); 


  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!name || !email) {
      alert('Please fill out both the name and email fields.');
      return;
    }
  
    try {
      console.log('Inviting:', { name, email });
  
      const response = await axios.post('https://api10.easychatdesk.com/add-team-member', {
        owner_token: token,
        name,
        email_address: email,
      });
  
      if (response.status === 201) {
        

        setEmail("");
        setName("");
        

        fetchTeamMembers();
      } else {
        alert(`Unexpected response: ${response.statusText}`);
      }
    } catch (error: any) {
      console.error('Error adding team member:', error);
      alert(
        error.response?.data?.error || 'Failed to add team member. Please try again later.'
      );
    }
  };



  const {
    config: { isDark }
  } = useAppContext();

  const handleRowClick = (visitor: any) => {
    setSelectedVisitor(visitor);
    setIsSidePanelOpen(true);
  };

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
    setSelectedVisitor(null);
  };


  const fetchTeamMembers = async () => {
    try {
        setLoading(true);
        const response = await axios.get(`https://api10.easychatdesk.com/get-team-members/${token}`);

        if(response.status == 204)
        {

        }
        else
        {
          setTeamMembers(response.data.teamMembers);  
        }

 

        setError(null); // Clear error if any
    } catch (err: any) {
        setError(err.response?.data?.message || 'Failed to fetch team members.');
        console.log(err);
    } finally {
        setLoading(false);
    }
};


  useEffect(() =>{


    //calling this get-team-members

    fetchTeamMembers();



  }, []);



  const handleDelete = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this team member?')) return;

    try {
      await axios.delete(`https://api10.easychatdesk.com/delete-team-member/${id}`);
      setTeamMembers((prev) => prev.filter((member: any) => member.id !== id)); // Remove member locally
      alert('Team member deleted successfully.');
    } catch (err: any) {
      console.error(err);
      alert(err.response?.data?.message || 'Failed to delete team member.');
    }
  };


  const displayTeam = () =>{
    return (<Row className="gy-3 mt-5">
      <Col md={12}>
        <h3>Current team members</h3>
      </Col>
      <Col md={12}>
        <Card>
          <Card.Body>
            {loading ? (
              <p>Loading team members...</p>
            ) : error ? (
              <p className="text-danger">{error}</p>
            ) : teamMembers.length > 0 ? (
              <Table striped hover>
                <thead>
                  <tr>
                    <th> </th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {teamMembers.map((member: any, index: number) => (
                    <tr key={member.id}>
                      <td>{index + 1}</td>
                      <td>{member.name}</td>
                      <td>{member.email_address}</td>
                      <td>Invite sent</td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDelete(member.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No team members found.</p>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>)
  }


 
  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto">
          <h2 className="mb-2 text-body-emphasis">Your Team</h2>
          <h5 className="text-body-tertiary fw-semibold">
            Invite and control your team members from this panel.
          </h5>
        </Col>
      </Row>

      <Card className="p-3" style={{maxWidth: "750px"}}>
      <Card.Body>
        <div>
          <p>Use the form below to invite a team member.</p>
        </div>
      <Form onSubmit={handleInvite}>
        <Row className="gy-3">
          <Col md={12}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={12}>
            <Button variant="primary" type="submit">
              Invite
            </Button>
          </Col>
        </Row>
      </Form></Card.Body></Card>


    {displayTeam()}

    </>
  );
};

export default AgentsPage;
