import React, { useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';

interface Plan {
  title: string;
  monthlyPrice: string;
  yearlyPrice: string;
  monthlyLink: string;
  yearlyLink:string;
  features: string[];
}

const PricingPlans: React.FC = () => {
  const [isYearly, setIsYearly] = useState(false);

  const plans: Plan[] = [
    {
      title: 'Free',
      monthlyPrice: '0',
      monthlyLink: "",
      yearlyLink: "",
      yearlyPrice: '0',
      features: ['Price Per Agent', 'Basic Customisation', '1 User', 'Track 20 Visitors', '30 Day Chat History' ,'Basic Support', 'Limited Chat History']
    },
    {
      title: 'Starter',
      monthlyPrice: '15',
      yearlyPrice: '144',
      monthlyLink: "https://easychatdesk.com/?get_membership=yes&package=starter_monthly",
      yearlyLink: "https://easychatdesk.com/?get_membership=yes&package=starter_yearly",
      features: ['Price Per Agent', 'Advanced Customisation', 'Unlimited Team Mates', 'Track 100 Visitors', 'Unlimited Chat History', 'Priority Support', 'Unlimited Chat History', 'Supports File Uploads']
    },
    {
      title: 'Professional',
      monthlyPrice: '33',
      yearlyPrice: '312',
      monthlyLink: "https://easychatdesk.com/?get_membership=yes&package=professional_monthly",
      yearlyLink: "https://easychatdesk.com/?get_membership=yes&package=professional_yearly",
      features: ['Price Per Agent', 'Advanced Customisation', 'Unlimited Team Mates', 'Track 500 Visitors', 'Unlimited Chat History', 'AI Chatbot', 'Advanced Analytics', 'Unlimited Tickets', 'Supports File Uploads']
    },
    {
      title: 'Business',
      monthlyPrice: '59',
      yearlyPrice: '564',
      monthlyLink: "https://easychatdesk.com/?get_membership=yes&package=business_monthly",
      yearlyLink: "https://easychatdesk.com/?get_membership=yes&package=business_yearly",
      features: ['Price Per Agent', 'Advanced Customisation', 'Unlimited Team Mates',  'Track 1000 Visitors', 'Unlimited Chat History',  'AI Chatbot', 'Dedicated Account Manager', 'White Label Chat Widget', 'Custom Integrations', '24/7 Support', 'Supports File Uploads']
    }
  ];

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-center mb-4">
        <Form.Switch 
          id="pricing-switch" 
          label="Yearly Pricing" 
          checked={isYearly} 
          onChange={() => setIsYearly(!isYearly)} 
        />
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-1"></div>


        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-10">
        <div className="row">
        {plans.map((plan, index) => (
          <div key={index} className="col-md-3 mb-4">
            <Card className="h-100 shadow-sm">
              <Card.Body>
                <Card.Title className="text-center">{plan.title}</Card.Title>
                <h2 className="text-center">
                  ${isYearly ? plan.yearlyPrice : plan.monthlyPrice}
                  <small className="text-muted fs-6"> / {isYearly ? 'year' : 'month'}</small>
                </h2>

                <div className="d-grid mb-10 mt-4">
                    {
                        index == 0 ?
                        <>
                        <p style={{borderWidth:1, borderStyle:"solid", borderColor: "#aaa", borderRadius: 5,
                            textAlign:"center", padding: 13}}>You are on this plan</p>
                        </>
               :
                  <Button variant="outline-secondary" size="lg"
                  onClick={() => window.location.href =  isYearly ? plan.yearlyPrice: plan.monthlyLink }
                >
                    {plan.title === 'Free' ? 'Get Started' : 'Choose Plan'}
                  </Button> }
                </div>


                <ul className="list-unstyled mt-5">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="mb-2">✓ {feature}</li>
                  ))}
                </ul>
               
              </Card.Body>
            </Card>
          </div>
        ))}

</div>
        </div>


<div className="col-md-12 col-lg-12 col-xl-12 col-xxl-1"></div>
      </div>
    </div>
  );
};

export default PricingPlans;
