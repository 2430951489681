import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  faTachometerAlt,
  faTicket, faUserPlus,
  faChartPie,
  faUsers, faRobot, faCreditCard, faCog, faFlag,
  faChartSimple, faUserCog, faBoxArchive,
  faThLarge,

  faUser,
  faUsersViewfinder
} from '@fortawesome/free-solid-svg-icons';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,

    pages: [
      {
        name: 'Dashboard',
        icon: faTachometerAlt,
        iconSet: 'font-awesome',
        path: '/',
        pathName: 'deafult-dashboard',
        active: true
      },

      {
        name: 'Chats',
        icon: faUser,
        iconSet: 'font-awesome',
        path: '/apps/chat',
        pathName: 'chats-page',
        active: true
      },

      {
        name: 'Traffic',
        icon: faUsersViewfinder,
        iconSet: 'font-awesome',
        path: '/traffic',
        pathName: 'traffic-page',
        active: true
      },

      {
        name: 'Archives',
        icon: faBoxArchive,
        iconSet: 'font-awesome',
        path: '/chat-archives',
        pathName: 'archives-page',
        active: true
      },



      {
        name: 'Statistics',
        icon: faChartSimple,
        iconSet: 'font-awesome',
        path: '/statistics',
        pathName: 'statistics-page',
        active: true
      },

      {
        name: 'Reports',
        icon: faChartPie,
        iconSet: 'font-awesome',
        path: '/reports',
        pathName: 'reports-page',
        active: true
      },

      {
        name: 'Agents',
        icon: faUsers,
        iconSet: 'font-awesome',
        path: '/agents',
        pathName: 'agents-page',
        active: true
      },



      {
        name: 'Extra Apps',
        icon: faThLarge,
        iconSet: 'font-awesome',
        path: '/extra-apps',
        pathName: 'extra-apps-page',
        active: true
      },


      {
        name: 'ChatBot',
        icon: faRobot,
        iconSet: 'font-awesome',
        path: '/chat-bot',
        pathName: 'chat-bot-page',
        active: true
      },
 
    ]
  },

  {
    label: 'CRM Area',
    icon: UilCube,
    pages: [

      {
        name: 'Customers',
        icon: faUserPlus,
        iconSet: 'font-awesome',
        path: '/customers',
        pathName: 'customers-page',
        active: true
      },



      {
        name: 'Contact Forms',
        icon: faUserPlus,
        iconSet: 'font-awesome',
        path: '/contact-forms',
        pathName: 'contact-forms-page',
        active: true
      },


      {
        name: 'Tickets',
        icon: faTicket,
        iconSet: 'font-awesome',
        path: '/tickets',
        pathName: 'archives-page',
        active: true
      },


    ]},
  {
    label: 'Account Area',
    icon: UilCube,
    pages: [

      {
        name: 'Billing',
        icon: faCreditCard,
        iconSet: 'font-awesome',
        path: '/billing',
        pathName: 'billing-page',
        active: true
      },


      {
        name: 'Settings',
        icon: faCog,
        iconSet: 'font-awesome',
        path: '/settings',
        pathName: 'settings-page',
        active: true,
      },




      {
        name: 'Notifications',
        icon: faFlag,
        iconSet: 'font-awesome',
        path: '/notifications',
        pathName: 'notifications-page',
        active: true
      },






      {
        name: 'User Account',
        icon: faUserCog,
        iconSet: 'font-awesome',
        path: '/user-account',
        pathName: 'account-page',
        active: true
      },

 

 
 
    
    ]
  },



];
