import React from 'react';
import { Offcanvas } from 'react-bootstrap';

interface SidePanelProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
}

const SidePanel: React.FC<SidePanelProps> = ({
  show,
  onClose,
  children,
  title = 'Details'
}) => {
  return (
    <Offcanvas show={show} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{children}</Offcanvas.Body>
    </Offcanvas>
  );
};

export default SidePanel;
