import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import { useAppDispatch, useAppSelector } from 'hooks';
import { login } from 'store/slices/authSlice';

const LoginScreen: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);
  const navigate = useNavigate();

  // Set page title
  useEffect(() => {
    const prevTitle = document.title;
    document.title = 'Login to EasyChatDesk';
    return () => {
      document.title = prevTitle;
    };
  }, []);

  // If already logged in, redirect to home
  if (auth.token) {
    return <Navigate to="/" />;
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Dispatch the login thunk and wait for its resolution
    const result = await dispatch(login({ username, password }));

    if (login.fulfilled.match(result)) {
      navigate('/');
      window.location.reload();
    } else if (login.rejected.match(result)) {
      setErrorMessage(result.payload as string);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 "></div>
        <div className="col-md-4">
          <h3 className="text-center mt-5">EasyChatDesk Dashboard Login</h3>
          <div className="text-center mb-5">
            Login to your account to interact with your customers
          </div>
          <div className="card my-5" id="login-form1">
            <form
              onSubmit={handleSubmit}
              className="card-body cardbody-color p-lg-5"
            >
              <div className="text-center">
                <img
                  src="https://easychatdesk.com/images/easychatdesklogo.png"
                  width="200px"
                  alt="profile"
                  className="mb-5"
                />
              </div>

              {auth.status === 'failed' && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}

              {auth.status === 'loading' && <LoadingSpinner />}

              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="Username"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  placeholder="Email Address..."
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Password..."
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-success px-5 mb-5 w-100"
                  disabled={auth.status === 'loading'}
                >
                  Login
                </button>
              </div>
              <div id="emailHelp" className="form-text text-center">
                Not registered?{' '}
                <a href="http://easychatdesk.com/register" className="fw-bold">
                  Create an Account
                </a>
              </div>
              <div id="emailHelp" className="form-text text-center mb-5">
                <a href="http://easychatdesk.com/register" className="fw-bold">
                  Lost your password?
                </a>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  );
};

export default LoginScreen;
