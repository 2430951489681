import { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useChatContext } from 'providers/ChatProvider';
import PhoenixOffcanvas, {
  PhoenixOffcanvasContainer
} from 'components/base/PhoenixOffcanvas';
import ConversationDetails from '../conversation-details';
import ChatSidebar from '../ChatSidebar';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import ChatContentHeader from './ChatContentHeader';
import ConversationStarter from './ConversationStarter';
import ChatContentFooter from './ChatContentFooter';
import Message from '../message';
import Scrollbar from 'components/base/Scrollbar';
import { SET_CHAT_STATE } from 'reducers/ChatReducer';

import { useAppContext } from 'providers/AppProvider';
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Import the RootState type from your store
import { useDispatch } from 'react-redux';

const ChatContent = ({ currentChatCustomerID }: any) => {
  const customers = useSelector((state: RootState) => state.traffic.customers);
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();


  const myCustomer = getCurrentCustomer(currentChatCustomerID);

  const {
    config: { isDark }
  } = useAppContext();

  function getCurrentCustomer(customer_id: any) {
    const currentCustomer = customers.find(
      item => item.customer_id === customer_id
    );
    return currentCustomer;
  }
  const {
    currentConversation,
    chatDispatch,
    showConversationDetails,
    showUserListOffcanvas,
    setShowConversationDetails
  } = useChatContext();

  const messageEndRef = useRef<null | HTMLSpanElement>(null);

  const { breakpoints } = useBreakpoints();

  useEffect(() => {
 
    messageEndRef.current?.scrollIntoView();
  }, [ myCustomer?.messages]);

  const getCountryImageSRC = (country: any) => {
    if (country != undefined) {
      const country2 = country.toLowerCase();
      return 'https://cdn.easychatdesk.com/flags/' + country2 + '.svg';
    }

    return '';
  };

  function displayCustomerName(itemName: any) {
    if (itemName == null) return '';
    let name = 'Unnamed Customer';
    if (itemName.name != undefined) name = itemName.name;
    if (itemName.name == '') name = 'Unnamed Customer';

    return name;
  }

  function displayCurrentUserHistory(cuser: any) {
    if (cuser != null) {
      if (cuser.history == null) return null;

      if (cuser.history.length === 0) return <li>No visits</li>;

      // Reverse the history array before mapping
      return cuser.history
        .slice()
        .reverse()
        .map((historyItem: any) => {
          return (
            <li className="mb-1" key={historyItem.visited_page}>
              <a
                href={historyItem.visited_page}
                title={historyItem.visited_page}
                target="_blank"
                rel="noopener noreferrer"
              >
                {historyItem.title.substring(0, 43)}...
              </a>
            </li>
          );
        });
    } else {
      return <li>No visits</li>;
    }
  }

  if (currentChatCustomerID) {
    
    const selectedVisitor = myCustomer;

    return (
      <>
        <Card as={PhoenixOffcanvasContainer} className="h-100 w-100">
          <ChatContentHeader />
          <Card.Body className="p-3 p-sm-4 scrollbar d-flex flex-column gap-2">
            {myCustomer?.messages.length === 0 && <ConversationStarter />}
            {myCustomer?.messages.map((message: any, index: any) => (
              <Message message={message} key={index} />
            ))}
            <span ref={messageEndRef} />
          </Card.Body>

          <ChatContentFooter currentChatCustomerID={currentChatCustomerID} />

          <PhoenixOffcanvas
            open={showConversationDetails}
            placement="top"
            noBackdrop
            className="bg-body-emphasis w-100 z-index-0 rounded-3"
          >
            <Scrollbar></Scrollbar>
          </PhoenixOffcanvas>

          {breakpoints.down('sm') && (
            <PhoenixOffcanvas
              open={showUserListOffcanvas}
              placement="start"
              noBackdrop
              className="w-100 z-index-0"
            >
              <ChatSidebar className="border-0 h-100" />
            </PhoenixOffcanvas>
          )}
        </Card>

        <div className="card scrollbar chatPageScrollBar">
          <div className="card-body p-3">
            <div className="w-100 mb-4 mt-2 text-center">
              <div className="circle-avatar-1 text-center">UC</div>
            </div>

            <h4 className="text-center mb-0">
              {displayCustomerName(selectedVisitor)}
            </h4>

            <p className="text-center  mb-0">
              {selectedVisitor?.region}, {selectedVisitor?.city}
            </p>
            <p className="text-center">{selectedVisitor?.country_name}</p>

            <div className="text-center">
              <img
                src={getCountryImageSRC(selectedVisitor?.country)}
                width="28"
              />
            </div>

            <div className={isDark ? 'line-line-1-dark' : 'line-line-1'}></div>

            <div className="w-100">
              <h5 className="card-user-1-title mb-4 mt-3">Visited Pages</h5>
              <div className="">
                <ul className="no-padding-no-margin">
                  {displayCurrentUserHistory(selectedVisitor)}
                </ul>
              </div>
            </div>

            <div className={isDark ? 'line-line-1-dark' : 'line-line-1'}></div>

            <div className="w-100">
              <h5 className="card-user-1-title mb-4 mt-3">
                Additional Information
              </h5>
              <div className="w-100">
                <p className="mb-1">Chat Time: 1 minute</p>
                <p className="mb-1">First time visitor: 1 visit</p>
                <p className="mb-1">Chats: no chats</p>
                <p className="mb-1">
                  Referral: <b>{selectedVisitor?.refer}</b>
                </p>
                <p className="mb-1">
                  Current Page: <b>{selectedVisitor?.title}</b>
                </p>
              </div>
            </div>

            <div className={isDark ? 'line-line-1-dark' : 'line-line-1'}></div>

            <div className="w-100">
              <h5 className="card-user-1-title mb-4 mt-3">
                More Customer Information
              </h5>
              <div className="w-100">
                <p className="mb-1">IP: {selectedVisitor?.ip}</p>
                <p className="mb-1">Browser: {selectedVisitor?.browser}</p>
                <p className="mb-1">Device: Computer</p>
                <p className="mb-1">OS: {selectedVisitor?.os}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default ChatContent;
