import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Toast } from 'react-bootstrap';
import { RootState } from 'store'; // Adjust import path

import { useAppContext } from 'providers/AppProvider';

const CodeDisplayCard: React.FC = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const [showToast, setShowToast] = useState(false);

  const {
    config: { isDark }
  } = useAppContext();

  const codeSnippet = `

  <!-- Begin of easychatdesk website widget -->
    <script>
    window.__ecd = window.__ecd || {};
    window.__ecd.license = '${token}';
    !function(t,n,e){function a(t){return null}({_q:[],_n:null,v:'1.0',init:function()
    {var t=document.createElement('script');
    t.type='text/javascript',t.async=!0,t.src='https://cdn.easychatdesk.com/tracking.js?am=98212&license=' + window.__ecd.license;var n=document.getElementsByTagName('head')[0];n.appendChild(t,n)}}).init()}(window,document,[].slice);
    </script>
    <noscript><a href="https://easychatdesk.com/" rel="nofollow">Powered by EasyChatDesk</a></noscript>
 <!-- End of easychatdesk website widget -->
  `;

  const handleCopy = () => {
    navigator.clipboard.writeText(codeSnippet);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 2000); // Hide toast after 2 seconds
  };

  return (
    <>
      <Card style={{ width: '100%', marginTop: '20px' }}>
        <Card.Body>
          <Card.Title>EasyChatDesk Widget Code</Card.Title>
          <p>
            Copy and paste this code in your website page. Preferably right before the {'</body>'} tag closes.
          </p>
          <pre style={{ background: isDark ? '#333' : "#f0f0f0", padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' }}>
            {codeSnippet}
          </pre>
          <Button variant="success" className="mt-3" onClick={handleCopy}>
            Copy to Clipboard
          </Button>


          <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={2000}
        autohide  style={{marginTop: 15, backgroundColor: "green",   color:"white"}}
      >
        <Toast.Body>Code copied to clipboard!</Toast.Body>
      </Toast>


        </Card.Body>
      </Card>

 
      
    </>
  );
};

export default CodeDisplayCard;
