import EcomTopCouponsChart from 'components/charts/e-charts/EcomTopCouponsChart';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const EcomTopCouponsCard = () => {


  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );

  const activeCount = trafficInformation.filter((item) => item.active).length;

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Online Users</h5>
            <h6 className="text-body-tertiary">Check your online users</h6>
          </div>
        </div>
   
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">
              Online Active Users
            </h6>
            <h6 className="text-body fw-semibold mb-0">{activeCount}</h6>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary-lighter me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">
              Total Users
            </h6>
            <h6 className="text-body fw-semibold mb-0">{trafficInformation.length}</h6>
          </div>
   
        </div>
      </Card.Body>
    </Card>
  );
};

export default EcomTopCouponsCard;
