import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const ChatBotPage = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );

  console.log(trafficInformation);

  const {
    config: { isDark }
  } = useAppContext();

  const handleRowClick = (visitor: any) => {
    setSelectedVisitor(visitor);
    setIsSidePanelOpen(true);
  };

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
    setSelectedVisitor(null);
  };

  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto">
          <h2 className="mb-2 text-body-emphasis">AI ChatBot</h2>
          <h5 className="text-body-tertiary fw-semibold">
           Enable chat bot features to automate customer support and capture more leads.
          </h5>
        </Col>
      </Row>
    </>
  );
};

export default ChatBotPage;
