import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Language = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );
 

  const {
    config: { isDark }
  } = useAppContext();

 

  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto">
          <h2 className="mb-2 text-body-emphasis">Language</h2>
          <h5 className="text-body-tertiary fw-semibold">
           View and change your chat settings, and the way your customers interact with you.
          </h5>
        </Col>
      </Row>
    </>
  );
};

export default Language;
