import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const PositionSettings: React.FC = () => {
  const [align, setAlign] = useState('right');
  const [sideSpacing, setSideSpacing] = useState(0);
  const [bottomSpacing, setBottomSpacing] = useState(0);

  return (
    <Form>
      <Row className="align-items-center">
        {/* Widget Position */}
        <Col md={12}>
          <p>Widget Position</p>
        </Col>
        </Row>
        <Row className="align-items-center">

        {/* Align To */}
        <Col md={2}>
          <Form.Group controlId="alignTo">
            <Form.Label>Align to:</Form.Label>
            <Form.Control 
              as="select" 
              value={align} 
              onChange={(e) => setAlign(e.target.value)}
            >
              <option value="right">Right</option>
              <option value="left">Left</option>

            </Form.Control>
          </Form.Group>
        </Col>

        {/* Side Spacing */}
        <Col  md={2}>
          <Form.Group controlId="sideSpacing">
            <Form.Label>Side spacing:</Form.Label>
            <Form.Control
              type="number"
              value={sideSpacing}
              onChange={(e) => setSideSpacing(Number(e.target.value))}
              placeholder="px"
            />
          </Form.Group>
        </Col>

        {/* Bottom Spacing */}
        <Col  md={2}>
          <Form.Group controlId="bottomSpacing">
            <Form.Label>Bottom spacing:</Form.Label>
            <Form.Control
              type="number"
              value={bottomSpacing}
              onChange={(e) => setBottomSpacing(Number(e.target.value))}
              placeholder="px"
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default PositionSettings;
