import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Badge from 'components/base/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppContext } from 'providers/AppProvider';
import { RootState } from 'store'; // Adjust import path
import { startChat } from 'store/slices/trafficSlice';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

interface VisitorsTableProps {
  onRowClick: (visitor: VisitorData) => void;
  trafficInformation: any[];
  customerTypes: string;
}

const getCountryImageSRC = (country: any) => {
  if (country != undefined) {
    const country2 = country.toLowerCase();
    return 'https://cdn.easychatdesk.com/flags/' + country2 + '.svg';
  }

  return '';
};

interface VisitorData {
  name: string;
  email: string;
  browser: string;
  country: string; // Assuming 'country' is a country code like 'IN'
  os: string;
  active: boolean;
  chat_started: boolean;
  customer_id: string;
}

const columns: ColumnDef<VisitorData>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ getValue }) => {
      const name = getValue<string>();
      return (
        <p>{name ? <p className="pt-2">{name}</p> : <p>Unnamed Customer</p>}</p>
      );
    },
    meta: {
      headerProps: {
        className: 'fs-10'
      },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ getValue }) => {
      const email = getValue<string>();
      return <p>{email ? <p className="pt-2">{email}</p> : <p>-</p>}</p>;
    },
    meta: {
      headerProps: {
        className: 'fs-10'
      },
      cellProps: { className: 'py-2' }
    }
  },

  {
    accessorKey: 'active',
    header: 'Status',
    cell: ({ getValue }) => {
      const isActive = getValue<boolean>();
      return (
        <Badge bg={isActive ? 'success' : 'secondary'} text="light">
          {isActive ? 'Active' : 'Inactive'}
        </Badge>
      );
    },
    meta: {
      headerProps: {
        className: 'fs-10'
      },
      cellProps: { className: 'py-2' }
    }
  },

  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => {
      const { chat_started, active, customer_id } = row.original;

      const navigate = useNavigate();
      const dispatch = useDispatch();

      const token = useSelector((state: RootState) => state.auth.token);

      function generateUniqueId() {
        return `${Date.now().toString(36)}-${Math.random().toString(36).substr(2, 9)}`;
      }

      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevents the row click handler from being triggered

        if (!chat_started) {
          dispatch(
            startChat({
              customerId: customer_id,
              message: 'Hello, how are you',
              ownerToken: token ? token : '',
              messageId: generateUniqueId()
            })
          );
        } else {
          navigate('/apps/chat/' + customer_id + '/conversation');
        }
      };

      return active == false ? (
        <p>Left website</p>
      ) : chat_started == true ? (
        <button className={`btn btn-outline-secondary`} onClick={handleClick}>
          Chat Started
        </button>
      ) : (
        <button
          className={`btn ${chat_started ? 'btn-secondary' : 'btn-primary'}`}
          onClick={handleClick}
        >
          Start Chat
        </button>
      );
    },
    meta: {
      headerProps: {
        className: 'fs-10'
      },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'browser',
    header: 'Browser',
    cell: ({ getValue }) => <p className="pt-2">{getValue<string>()}</p>,
    meta: {
      headerProps: {
        className: 'fs-10'
      },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'country',
    header: 'Country',
    cell: ({ getValue }) => {
      const countryCode = getValue<string>();
      return (
        <div className="d-flex align-items-center">
          <img src={getCountryImageSRC(countryCode)} width="28" />
          <span className="ms-2">{countryCode}</span>
        </div>
      );
    },
    meta: {
      headerProps: {
        className: 'fs-10'
      },
      cellProps: { className: 'py-2' }
    }
  },
  {
    accessorKey: 'os',
    header: 'OS',
    cell: ({ getValue }) => <p className="pt-2">{getValue<string>()}</p>,
    meta: {
      headerProps: {
        className: 'fs-10'
      },
      cellProps: { className: 'py-2' }
    }
  }
];
export const VisitorsTable = ({
  onRowClick,
  trafficInformation,
  customerTypes
}: VisitorsTableProps) => {



    const filteredData = useMemo(() => {
      if (customerTypes === 'all_customers') {
        return trafficInformation;
      }
      else if (customerTypes === 'chatting') {
        return trafficInformation.filter(item => item.chat_started == true);;
      }

      else if (customerTypes === 'inactive') {
        return trafficInformation.filter(item => item.active == false);;
      }

      else if (customerTypes === 'active') {
        return trafficInformation.filter(item => item.active == true);;
      }


    return trafficInformation;

  }, [customerTypes, trafficInformation]);



  const table = useAdvanceTable({
    data: filteredData,
    filter: customerTypes,
    columns,    
    pageSize: 55,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <AdvanceTable
        tableProps={{
          size: 'sm',
          className: 'phoenix-table fs-8 mb-0 mt-3'
        }}
        headerClassName="fs-0"
        rowClassName="handcursor"
        onRowClick={row => onRowClick(row.original)}
      />
      <AdvanceTableFooter
        className="pt-3 pb-4"
        tableInfo="fs-8"
        viewAllBtnClass="ms-auto"
      />
    </AdvanceTableProvider>
  );
};
