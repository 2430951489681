import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { ChangeEvent, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import EmojiPicker from 'components/base/EmojiPicker';
import { useChatContext } from 'providers/ChatProvider';
import ReactTextareaAutosize from 'react-textarea-autosize';
import AttachmentPreview from 'components/common/AttachmentPreview';
import { convertFileToAttachment } from 'helpers/utils';
import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
 
import {
  faEllipsis,
  faImage,
  faMicrophone, 

  faPaperPlane,
  faPaperclip
} from '@fortawesome/free-solid-svg-icons';
import { SENT_MESSAGE } from 'reducers/ChatReducer';

import { useAppContext } from 'providers/AppProvider';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Import the RootState type from your store
import { useDispatch } from 'react-redux';

import {
  startChat,
  sendTypingMessage,
  sendMessage,   endChat
} from 'store/slices/trafficSlice';

const ChatContentFooter = ({ currentChatCustomerID }: any) => {
  const customers = useSelector((state: RootState) => state.traffic.customers);
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();


  const {
    config: { isDark }
  } = useAppContext();

  console.log(currentChatCustomerID);

  function getCurrentCustomer(customer_id: any) {
    const currentCustomer = customers.find(
      item => item.customer_id === customer_id
    );
    return currentCustomer;
  }

  const currentCustomer = getCurrentCustomer(currentChatCustomerID);
  const loggedinAgent = token;

  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);

  const sentMessageStartChat = () => {
    dispatch(
      startChat({
        customerId: currentChatCustomerID,
        message: messageText == '' ? 'Hello, how are you' : messageText,
        ownerToken: token ? token : '',
        messageId: generateUniqueId()
      })
    );

    setMessageText('');
    setImageAttachments([]);
    setFileAttachment(null);
  };

  const triggerTypingMessage = () => {
    dispatch(
      sendTypingMessage({
        customerId: currentChatCustomerID,
        ownerToken: token ? token : ''
      })
    );
  };


  function generateUniqueId() {
    return `${Date.now().toString(36)}-${Math.random().toString(36).substr(2, 9)}`;
  }


  const endChatNow = () =>
  {
    dispatch(
      endChat({
        messageId: generateUniqueId(),
        customerId: currentChatCustomerID,
        message: "Support agent ended the chat.",
        ownerToken: token ? token : ''
      })
    );
  }

  const sentMessage = () => {
    if (messageText || fileAttachment || imageAttachments.length > 0) {
      dispatch(
        sendMessage({
          messageId: generateUniqueId(),
          customerId: currentChatCustomerID,
          message: messageText,
          ownerToken: token ? token : ''
        })
      );

      /*  customersDispatch({
        type: trafficActionTypes.SEND_MESSAGE_TO_USER,
        payload: {
          agentToken: loggedinAgent,
          customer_id: currentChatConversation,
          message: messageText,
          attachments: {
            images: imageAttachments.map(imageAttachment =>
              URL.createObjectURL(imageAttachment)
            ),
            file: fileAttachment
              ? convertFileToAttachment(fileAttachment)
              : undefined
          }
        }
      }); */

      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
    }
  };

  return (
    <Card.Footer>
      {currentCustomer?.customer_typing_text == true ? (
        <div
          style={{
            padding: 10,
            backgroundColor: isDark ? '#333' : "#f2f2f2",
            borderRadius: 10,
            fontStyle: 'italic'
          }}
          className="mb-3"
        >
          Customer typing: {currentCustomer?.customer_typed_text} ...
        </div>
      ) : null}

      <ReactTextareaAutosize
        minRows={1}
        maxRows={6}
        value={messageText}
        placeholder="Type your message..."
        onChange={({ target: { value } }) => {
          setMessageText(value);
          triggerTypingMessage();
        }}
        className="chat-textarea form-control outline-none border-0 scrollbar resize-none mb-1 p-0 fs-8 fw-normal"
      />

      {fileAttachment && (
        <div className="mb-2">
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => setFileAttachment(null)}
          />
        </div>
      )}

      {imageAttachments && (
        <div className="mb-2 d-flex gap-2">
          {imageAttachments.map((attachment, index) => (
            <ImageAttachmentPreview
              key={index}
              image={URL.createObjectURL(attachment)}
              handleClose={() => {
                setImageAttachments(
                  imageAttachments.filter((_, i) => index !== i)
                );
              }}
            />
          ))}
        </div>
      )}

      <div className="d-flex gap-3 align-items-center">
        <EmojiPicker
          onSelect={selection => {
            setMessageText(messageText => messageText + selection.emoji);
          }}
        >
          <Button variant="link" className="p-0 text-body fs-9 btn-emoji">
            <FontAwesomeIcon icon={faFaceSmile} />
          </Button>
        </EmojiPicker>
        <div>
          <Button className="p-0">
            <label className="text-body fs-9 cursor-pointer" htmlFor="images">
              <FontAwesomeIcon icon={faImage} />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            accept="image/*"
            id="images"
            multiple
            onChange={({ target: { files } }: ChangeEvent<HTMLInputElement>) =>
              files && setImageAttachments(Array.from(files))
            }
          />
        </div>
        <div>
          <Button className="p-0">
            <label
              className="text-body fs-9 cursor-pointer"
              htmlFor="attachments"
            >
              <FontAwesomeIcon icon={faPaperclip} />
            </label>
          </Button>
          <Form.Control
            className="d-none"
            type="file"
            id="attachments"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.rar"
            onChange={({
              target: { files }
            }: ChangeEvent<HTMLInputElement>) => {
              files && setFileAttachment(files[0]);
            }}
          />
        </div>

        <Button className="p-0 text-body fs-9">
          <FontAwesomeIcon icon={faMicrophone} />
        </Button>
        <Button className="p-0 text-body fs-9">
          <FontAwesomeIcon icon={faEllipsis} />
        </Button>

        {currentCustomer?.chat_started == false ? (
          <Button
            variant="success"
            endIcon={<FontAwesomeIcon icon={faPaperPlane} className="ms-2" />}
            className="ms-auto"
            type="submit"
            onClick={sentMessageStartChat}
            disabled={currentCustomer.active == true ? false : true}
          >
            Start Chat
          </Button>
        ) : (
          <Button
            variant="primary"
            endIcon={<FontAwesomeIcon icon={faPaperPlane} className="ms-2" />}
            className="ms-auto"
            type="submit"
            onClick={sentMessage}
            disabled={currentCustomer?.active == true ? false : true}
          >
            Send
          </Button>
        )}
      </div>

        {
          currentCustomer?.active == true ?
        
        <div className="">

        {
          currentCustomer?.chat_started == true ?

        <Button onClick={endChatNow} className="btn btn-sm btn-danger">
          End Chat
        </Button> : null

}
        </div> : null }

    </Card.Footer>
  );
};

export default ChatContentFooter;
