 
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import EmailLayout from 'layouts/EmailLayout';
import BulkSelectProvider from 'providers/BulkSelectProvider';
import InboxToolbar from 'components/modules/email/InboxToolbar';
import EmailRow from 'components/modules/email/EmailRow';
import { emails } from 'data/email';
 
import {  Form } from 'react-bootstrap';
import axios from 'axios';

import { Collapse } from 'react-bootstrap';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Table, Pagination } from 'react-bootstrap';

import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import {
    faAngleLeft,
    faArchive,
    faPaperclip,
    faPrint,
    faReply,
    faReplyAll,
    faShare,
    faTrashCan
  } from '@fortawesome/free-solid-svg-icons';
  import team60 from 'assets/img/team/60.webp';
  import generic41 from 'assets/img/generic/41.png';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/base/Avatar';
import Button from 'components/base/Button'; 

import { Link } from 'react-router-dom';
import RevealDropdown, {
    RevealDropdownTrigger
  } from 'components/base/RevealDropdown';

  import TooltipIconButton from 'components/common/TooltipIconButton';
import AttachmentPreview from 'components/common/AttachmentPreview';
import { Message } from 'data/Message';
import { Ticket } from 'data/Ticket';

import { useAppSelector } from 'hooks';

const ViewTicket = () => {

  const [messageText, setMessageText] = useState<string>('');
  const { token, isMembershipValid } = useAppSelector(state => state.auth);

  const {
    config: { isDark }
  } = useAppContext();


    const { ticketId } = useParams();

    const [ticket, setTicket] = useState<Ticket | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


  const [openState, setOpenState] = useState<boolean[]>([]);

    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);
      
      // Options for displaying date in "Day Month Year" format
      const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
    
      // Format the date to "28 July 2024"
      return date.toLocaleDateString('en-GB', options);
    };


    const stripHtmlTags = (html: string) => {
      // Create a temporary DOM element to use browser's HTML parser
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    };


    const formatTime = (dateString: string): string => {
      const date = new Date(dateString);
    
      // Options for displaying time in 12-hour format with AM/PM
      const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
    
      // Format the time to "6:22 PM"
      return date.toLocaleTimeString('en-US', options);
    };


    const fetchTicketDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://api10.easychatdesk.com/get-ticket-details/${ticketId}`);

        setTicket(response.data.ticket);
        setMessages(response.data.messages);
        
        const messagesLength = response.data.messages.length;
        const initialState = Array(messagesLength).fill(false);

        // Set the last element to true if there is at least one message
        if (messagesLength > 0) {
          initialState[messagesLength - 1] = true;
          initialState[messagesLength - 2] = true;
 
        }

        setOpenState(initialState);

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch ticket details.');
        setLoading(false);
      }
    };


    useEffect(() => {

      fetchTicketDetails();
    }, [ticketId]);


    const  handleSendMessage =  async () => {
      if (messageText.trim() !== '') {



 
          try {
            const response = await axios.post('https://api10.easychatdesk.com/send-ticket-reply', {
              owner_token: token,
              ticket_id: ticketId,
              message: messageText
            });
      
            console.log('Success:', response.data);
            fetchTicketDetails();
            // Handle successful response (e.g., show a success message)
          } catch (error) {
            console.error('Error:', error);
            // Handle error (e.g., show an error message)
          }
 


    
        setMessageText(''); // Clear the textarea after sending
      }
    };


    if (loading) {
      return <p>Loading...</p>;
    }



// Function to replace <br/> with newline characters
const replaceBrWithNewLine = (message: string): string => {
  return message.replace(/<br\s*\/?>/gi, '');
};

// Function to convert plain URLs to clickable links
const linkify = (message: string): string => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  return message.replace(urlPattern, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};

// Function to process the message
const processMessage = (message: string): string => {
  const withNewLines = replaceBrWithNewLine(message);
  return linkify(withNewLines);
};


const toggleOpen = (index: number) => {
  setOpenState(openState.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
};


   return(<>


<EmailLayout page="detail">
      <Col md={12} className="mb-3">
        <Card className="email-content123">
          <Card.Header className="border-0">
            <div className="d-flex flex-between-center pb-3 border-bottom border-translucent">
              <Button
                as={Link}
                to="/tickets"
                variant="link"
                className="p-0 text-body-secondary me-3"
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className="fw-bolder fs-8"
                />
              </Button>
              <h3 className="flex-1 mb-0 lh-sm line-clamp-1">
                {ticket?.title}
              </h3>

              <RevealDropdownTrigger>
                <RevealDropdown>
                  <Dropdown.Item>Edit</Dropdown.Item>
                  <Dropdown.Item className="text-danger">Delete</Dropdown.Item>
                  <Dropdown.Item>Download</Dropdown.Item>
                  <Dropdown.Item>Report abuse</Dropdown.Item>
                </RevealDropdown>
              </RevealDropdownTrigger>
            </div>
          </Card.Header>
          <Card.Body className="p-0 pb-4 scrollbar">
            <div className="email-detail-content px-4">
              <Row className="align-items-center gy-3 gx-2 mb-5">
          
                <Col xs="auto">
                <Avatar size="xl" variant="name">
          {ticket?.name.charAt(0)}
        </Avatar>
                </Col>
                <Col xs="auto" className="flex-1">
                  <div className="d-flex mb-1">
                    <h5 className="mb-0 text-body-highlight me-2">
                   {ticket?.name}
                    </h5>
                    <p className="mb-0 lh-sm text-body-tertiary fs-9 d-none d-md-block text-nowrap">
                      &lt; {ticket?.email} &gt;
                    </p>
                  </div>
                  <p className="mb-0 fs-9">
          
                    <span className="text-body-highlight fw-semibold fs-10 me-1">
                      {formatDate(messages[0].date_created)}
                    </span>
                    <span className="fw-semibold text-body fs-10 me-1">
                    {formatTime(messages[0].date_created)}
                    </span>
                    <FontAwesomeIcon
                      icon={farStar}
                      className="text-body-quaternary"
                    />
                  </p>
                </Col>
              </Row>
              <div className="text-body-highlight fs-8 w-100 w-md-75 mb-2">
                {messages[0].message}
              </div>

  
            </div>
          </Card.Body>
        </Card>        
      </Col>

      {messages.slice(1).map((item: any, index: number) => (
        <Col md={12} className="mb-3" key={index}>
          <Card className="email-content123">
            {/* Clickable Card Header for Collapse Toggle */}
            <Card.Header onClick={() => toggleOpen(index)} className="cursor-pointer">
              <Row className="align-items-center gy-3 gx-2">
              
                <Col xs="auto">
                  <Avatar size="xl" variant="name">
                    {item.sender_name.charAt(0) === "" ? "UC" : item.sender_name.charAt(0)}
                  </Avatar>
                </Col>
                <Col xs="auto" className="flex-1">
                  <div className="d-flex mb-1">
                    <h5 className="mb-0 text-body-highlight me-2">{item.sender_name}</h5>
                    <p className="mb-0 lh-sm text-body-tertiary fs-9 d-none d-md-block text-nowrap">
                      &lt; {item?.sender_email} &gt;
                    </p>
                  </div>
                  <p className="mb-0 fs-9">
                    <span className="text-body-highlight fw-semibold fs-10 me-1">
                      {formatDate(item.date_created)}
                    </span>
                    <span className="fw-semibold text-body fs-10 me-1">
                      {formatTime(item.date_created)}
                    </span>
                    <FontAwesomeIcon icon={farStar} className="text-body-quaternary" />
                  </p>
                </Col>
              </Row>
            </Card.Header>

            {/* Collapsible Card Body */}
            <Card.Body className="p-0   scrollbar">
              <div className="email-detail-content p-4">
                {/* Display the first line when collapsed */}
                {!openState[index] && (
                  <p onClick={() => toggleOpen(index)} className="message-content mb-0 pb-0" style={{ cursor:"pointer", whiteSpace: "pre-line", color: isDark ? "#444" : "#ccc" }}>
                    {stripHtmlTags(item.message.split('\n')[0]) }
                  </p>
                )}
                {/* Full message when expanded */}
                <Collapse in={openState[index]}>
                  <div className="mt-2">
                    <p className="message-content" style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: processMessage(item.message) }} />
                  </div>
                </Collapse>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}


 
      <Col md={12} className='mb-3'>
      <Card className="p-3">
      <div className="message-box">
      <Form.Group>
        <Form.Label>Reply to the customer</Form.Label>
        <Form.Control 
          as="textarea" 
          rows={3} 
          placeholder="Type your message here..." 
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" onClick={handleSendMessage} className="mt-2">
        Send
      </Button>
    </div>
      </Card>
      </Col>


    </EmailLayout>




    
    </>)

}


export default ViewTicket;