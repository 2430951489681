import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Button from 'components/base/Button';
import IndeterminateCheckbox from 'components/base/IndeterminateCheckbox';
import StarCheckbox from 'components/base/StarCheckbox';
import { Email } from 'data/email';
import { getFileIcon } from 'helpers/utils';
import { useBulkSelect } from 'providers/BulkSelectProvider';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import { Ticket } from 'data/Ticket';


interface EmailRowProps {
  ticket: Ticket;
  index: number;
}

const EmailRow = ({ ticket, index }: EmailRowProps) => {


  const getTimeElapsed = (dateString: string): string => {
    const now = new Date();
    const pastDate = new Date(dateString);
  
    // Calculate the difference in milliseconds
    const diffInMs = now.getTime() - pastDate.getTime();
  
    // Time constants in milliseconds
    const minuteInMs = 1000 * 60;
    const hourInMs = minuteInMs * 60;
    const dayInMs = hourInMs * 24;
    const weekInMs = dayInMs * 7;
    const monthInMs = dayInMs * 30; // Approximate, 30 days per month
    const yearInMs = dayInMs * 365; // Approximate, 365 days per year
  
    // Calculate the elapsed time
    const minutes = Math.floor(diffInMs / minuteInMs);
    const hours = Math.floor(diffInMs / hourInMs);
    const days = Math.floor(diffInMs / dayInMs);
    const weeks = Math.floor(diffInMs / weekInMs);
    const months = Math.floor(diffInMs / monthInMs);
    const years = Math.floor(diffInMs / yearInMs);
  
    // Determine which unit to display
    if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (days < 7) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    } else if (months < 12) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    } else {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    }
  };
  



  return (<Link  to={"/tickets/" + ticket.ticket_id + "/conversation"} className='ticket-link'><div className="card p-3 mb-3">

    <div className="row">

    <Col className="col-auto">
        
        <Avatar size="m" variant="name">
          {ticket.name.charAt(0)}
        </Avatar>
   
    </Col>

    <Col className="col-auto">

      <h5>{ticket.title}</h5>
      <p className="mb-0 pb-0">{(ticket.name)} ({ticket.email})</p>
      <p>Latest update on this ticket: {getTimeElapsed(ticket.date_updated)}</p>
    
    </Col>

    <Col className="col-auto ms-auto">
    <span className="badge bg-success">Open</span>
    </Col>

    </div>


  </div></Link>);
 
  return (
    <div className="border-bottom border-translucent hover-actions-trigger py-3">
      <Row className="gx-2">
        <Col xs="auto">
          <div className="d-flex flex-column flex-sm-row">
            <IndeterminateCheckbox
        
              className="mb-2 m-sm-0 me-sm-2"
            />
            <Button className="btn p-0">
              <StarCheckbox defaultChecked={true} iconClass="fs-9" />
            </Button>
          </div>
        </Col>
        <Col className="col-auto">
        
            <Avatar size="s" variant="name">
              {ticket.name.charAt(0)}
            </Avatar>
       
        </Col>
        <Col className="col-auto">
          <Link
            to="#!"
            className={classNames('fs-9 inbox-link', {
              'text-body fw-semibold': "",
              'text-body-emphasis fw-bold': ""
            })}
          >
            {ticket.name}
          </Link>
        </Col>
        <Col className="col-auto ms-auto">
          <div className="hover-actions end-0">
            <Dropdown>
              <Dropdown.Toggle
                variant="phoenix-secondary"
                className="dropdown-caret-none btn-icon"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#!">Mark Unread</Dropdown.Item>
                <Dropdown.Item href="#!">Mark Important</Dropdown.Item>
                <Dropdown.Item href="#!">Archive</Dropdown.Item>
                <Dropdown.Item href="#!">Download</Dropdown.Item>
                <Dropdown.Item href="#!">Print</Dropdown.Item>
                <Dropdown.Item href="#!">Report Spam</Dropdown.Item>
                <Dropdown.Item href="#!">Report Phishing</Dropdown.Item>
                <Dropdown.Item href="#!">
                  Mute  
                </Dropdown.Item>
                <Dropdown.Item href="#!">
                  Block  
                </Dropdown.Item>
                <Dropdown.Item href="#!" className="text-danger">
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <span
            className={classNames('fs-10', {
              'fw-bold': ""
            })}
          >
   
          </span>
        </Col>
      </Row>
      <div className="ms-4 mt-n3 mt-sm-0 ms-sm-11">
        <Link to="/apps/email/email-detail" className="d-block inbox-link">
          <span
            className={classNames('fs-9 line-clamp-1', {
              'text-body-highlight': "",
              'text-body-emphasis':  ""
            })}
          >
            {ticket.title}
          </span>
          <p className="fs-9 ps-0 text-body-tertiary mb-0 line-clamp-2">
            {ticket.title}
          </p>
        </Link>

  
      </div>
    </div>
  );
};

export default EmailRow;
