import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PhoenixOffcanvas from 'components/base/PhoenixOffcanvas';
import SearchBox from 'components/common/SearchBox';
import EmailSidebar from 'components/modules/email/EmailSidebar';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import InboxToolbar from '../components/modules/email/InboxToolbar';
import { emails } from 'data/email';
import EmailRow from '../components/modules/email/EmailRow';
import { Link } from 'react-router-dom';
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import BulkSelectProvider from 'providers/BulkSelectProvider';

interface EmailLayoutProps {
  page: 'inbox' | 'detail' | 'compose';
}

const EmailLayout = ({
  children,
  page
}: PropsWithChildren<EmailLayoutProps>) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const { breakpoints } = useBreakpoints();
  const { setContentClass } = useMainLayoutContext();

  useEffect(() => {
    setContentClass('pt-0');

    return () => {
      setContentClass('');
    };
  }, []);
  return (
    <div className="email-container">
      <Row className="gx-lg-4 gx-xl-6 gx-3 py-4 z-2 position-sticky bg-body email-header">
        
        {children}
      </Row>
    </div>
  );
};

export default EmailLayout;
