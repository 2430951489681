import ChatContent from 'components/modules/chat/chat-content';
import ChatSidebar from 'components/modules/chat/ChatSidebar';
import { useBreakpoints } from 'providers/BreakpointsProvider';

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ChatConversation = () => {
  const { userId } = useParams();

  const { breakpoints } = useBreakpoints();

  useEffect(() => {
    //let customer = getCustomerByCustomerID(userId);
    //if(customer != undefined)
    // customersDispatch({ type: trafficActionTypes.SET_CURRENT_CHAT_CONVERSATION, payload: userId });
  }, [userId]);

  return (
    <>
      {breakpoints.up('sm') && <ChatSidebar />}
      <ChatContent currentChatCustomerID={userId} />
    </>
  );
};

export default ChatConversation;
