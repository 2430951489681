import { Button, CardBody, Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { useState } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CodeDisplayCard from './CodeSnippet';
import { Card } from 'react-bootstrap';

const InstallChatWidget = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any>(null);

  const trafficInformation = useSelector(
    (state: RootState) => state.traffic.customers
  );
 
  const token = useSelector((state: RootState) => state.auth.token);

  const {
    config: { isDark }
  } = useAppContext();

 

  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={12} xs="auto" className='mb-5'>
          <h2 className="mb-2 text-body-emphasis">Install EasyChatDesk Widget</h2>
          <h5 className="text-body-tertiary fw-semibold">
           Install your easychatdesk live chat video in your website
          </h5>
        </Col>

        <Col md={12} xs="auto" className='mb-2'>

            <h5>1. Install widget javascript code inside your website</h5>

          

            <CodeDisplayCard></CodeDisplayCard>

        </Col>



        <Col md={12} xs="auto" className='mb-2'>

            <h5 className='mb-3'>2. Install in your Wordpress Website</h5>

            <Card className='p-0'>
                <CardBody>
                    <p>We have a wordpress plugin ready to go. Just install the plugin, add your account token in the settings, and you are done. </p>
                    <p>Your token: <b>{token}</b></p>


                    <p>
                        <Button variant='outline-primary'>Wordpress Plugin</Button>
                    </p>
                
                </CardBody>
                
                      </Card>

        </Col>
      </Row>
    </>
  );
};




export default InstallChatWidget;
