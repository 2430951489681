import WebSocketService from 'services/WebsocketService';

let webSocketServiceInstance: WebSocketService | null = null;

export const getWebSocketService = () => {
  if (!webSocketServiceInstance) {
    throw new Error('WebSocketService is not initialized.');
  }
  return webSocketServiceInstance;
};

export const setWebSocketService = (wsService: WebSocketService | null) => {
  webSocketServiceInstance = wsService;
};
