import React from 'react';
import './LoadingSpinner.css';

// Define the props interface
interface LoadingSpinnerProps {
  centered?: boolean; // or number if you want it to be specifically 1/0
}

export default function LoadingSpinner({ centered }: LoadingSpinnerProps) {
  const classCenter = centered ? 'centered1' : '';

  return (
    <div className="spinner-container">
      <div className={classCenter}>
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}
