class WebSocketService {
  private socket: WebSocket | null = null;
  private url: string;
  private onMessageCallback: (message: any) => void;
  private onOpenCallback: () => void;
  private heartbeatInterval: number = 40000; // 40 seconds
  private heartbeatTimer: NodeJS.Timeout | null = null;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectDelay: number = 2000; // 2 seconds

  constructor(
    url: string,
    onMessageCallback: (message: any) => void,
    onOpenCallback: () => void
  ) {
    this.url = url;
    this.onMessageCallback = onMessageCallback;
    this.onOpenCallback = onOpenCallback;
  }

  connect() {
    if (this.socket) {
      this.disconnect();
    }

    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      console.log('WebSocket connected');
      this.onOpenCallback();
      this.startHeartbeat();
      this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
    };

    this.socket.onmessage = event => {
      this.onMessageCallback(JSON.parse(event.data));
    };

    this.socket.onerror = error => {
      console.error('WebSocket error:', error);
    };

    this.socket.onclose = () => {
      console.log('WebSocket disconnected');
      this.handleReconnect();
    };
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }

    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }

  sendLoginMessage(token: string) {
    const apiCall = {
      action: 'login',
      payload: { type: 'agent' },
      owner_token: token
    };

    this.sendMessage(apiCall);
  }

  private startHeartbeat() {
    this.heartbeatTimer = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.sendMessage({ action: 'heartbeat', payload: { nothing: '123' } });
      }
    }, this.heartbeatInterval);
  }

  sendMessage(message: any) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    }
  }

  private handleReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      const delay = this.reconnectDelay * this.reconnectAttempts;
      console.log(`Attempting to reconnect in ${delay / 1000} seconds...`);
      setTimeout(() => {
        this.connect();
      }, delay);
    } else {
      console.error(
        'Max reconnect attempts reached. Unable to reconnect to WebSocket.'
      );
    }
  }
}

export default WebSocketService;
