import { Col, Row, Pagination, Card } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import axios from 'axios';
import {Button, Form, Modal,} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/base/Avatar';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';


// Define types for customer data
interface Customer {
  customer_id: string;
  name: string;
  email: string;
}

const CustomersPage = () => {


  const usersPerPage = 10;

  const [customers, setCustomers] = useState<Customer[]>([]); // Array of customers
  const [loading, setLoading] = useState<boolean>(true);  // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [currentPage, setCurrentPage] = useState<number>(1); // Current page for pagination
  const [totalPages, setTotalPages] = useState<number>(1); // Total number of pages

  const token = useSelector((state: RootState) => state.auth.token);

  const [modalShow, setModalShow] = useState(false);
  const [ticketModalShow, setTicketModalShow] = useState(false);
 

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);


  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [ticketTitle, setTicketTitle] = useState('');
  const [ticketMessage, setTicketMessage] = useState('');


  const handleTicketModalShow = (customer: Customer) => {
    setSelectedCustomer(customer);
    setTicketModalShow(true);
  };
  const handleTicketModalClose = () => setTicketModalShow(false);


  const {
    config: { isDark }
  } = useAppContext();

  // Fetch customers with pagination
  const fetchCustomers = async (page: number) => {
    try {
      setLoading(true);
      const response = await axios.get<{ customers: Customer[], total_customers: number }>(
        `https://api10.easychatdesk.com/get-customers/${token}?page=${page}&limit=${usersPerPage}`
      );

      setCustomers(response.data.customers);
      const totalCustomers = response.data.total_customers;
      setTotalPages(Math.ceil(totalCustomers / usersPerPage)); // Assuming 10 customers per page
      setLoading(false);


      setName("");
      setEmail("");


    } catch (err) {
      console.error("Error fetching customers:", err);
      setError("Failed to load customers.");
      setLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchCustomers(page); // Fetch customers for the selected page
  };

  // Fetch customers on component mount or when page changes
  useEffect(() => {
    if (token) {
      fetchCustomers(currentPage);
    }
  }, [token, currentPage]); // Dependency on token and currentPage

  if (loading) {
    return <p>Loading customers...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }





  const handleSubmit = async (event:any) => {
    event.preventDefault();

    // Prepare data
    const data = {
      name,
      email,
      ownerToken: token, // Replace with the actual owner token
    };

    try {
      // Call your API endpoint
      const response = await axios.post('https://api10.easychatdesk.com/add-customer', data);
      console.log('Customer added:', response.data);
      handleClose(); // Close the modal on success
      fetchCustomers(currentPage);
    } catch (error) {
      console.error('Error adding customer:', error);
    }
  };



  const handleTicketSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedCustomer) return;

    console.log(selectedCustomer)

    const data = {
      customer_id: selectedCustomer.customer_id,
      title: ticketTitle,
      message: ticketMessage,
      ownerToken: token,
    };

    try {
      const response = await axios.post('https://api10.easychatdesk.com/add-ticket-from-dashboard', data);
      console.log('Ticket added:', response.data);
      handleTicketModalClose(); // Close the modal on success
    } catch (error) {
      console.error('Error adding ticket:', error);
    }
  };

  return (
    <>


<Modal show={ticketModalShow} onHide={handleTicketModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Ticket for {selectedCustomer?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleTicketSubmit}>
            <Form.Group className="mb-3" controlId="formTicketTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={ticketTitle}
                onChange={(e) => setTicketTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTicketMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter message"
                value={ticketMessage}
                onChange={(e) => setTicketMessage(e.target.value)}
                required
              />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleTicketModalClose}>Close</Button>
              <Button variant="primary" type="submit">Submit Ticket</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>



<Modal show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
              <Button variant="primary" type="submit">Add New</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>


  <Row className="gy-3 mb-6">
    <Col md={12} className="d-flex justify-content-between align-items-center">
      <div>
        <h2 className="mb-2 text-body-emphasis">Customers</h2>
        <h5 className="text-body-tertiary fw-semibold">
          This is the CRM functionality, check out your customers, create tickets, and send messages.
        </h5>
      </div>
      <Button variant="outline-primary" className="btn-sm ms-auto" onClick={handleShow}><FontAwesomeIcon icon={faPlus} className="me-2" /> Add New Customer</Button>
    </Col>
  </Row>

      <Row>
        {customers.length > 0 ? (
          customers.map((customer, index) => (
            <Col key={index} md={12} className="mb-2">
              <Card className='p-3'>
                <div className="row">
                <div className="col-12 col-md-8  d-flex ">

                <Avatar variant='name'  size="m">{customer.name.charAt(0)}</Avatar>
                  <div className="ps-2">
                  <h4>{customer.name}</h4>
                  <p className="pb-0 mb-0">{customer.email}</p>
                  </div>
                </div>

                    <div className='col-12 col-md-4 text-end'>
                    <button className="btn btn-primary btn-sm ms-2">View Details</button>
                    <button className="btn btn-primary btn-sm ms-2" onClick={() => handleTicketModalShow(customer)}>Create Ticket</button>
                    </div>

                </div>
              </Card>
            </Col>
          ))
        ) : (
          <p>No customers found.</p>
        )}
      </Row>

      {/* Pagination Component */}
      <Pagination className="justify-content-center">
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </>
  );
};

export default CustomersPage;
