import PropTypes from 'prop-types';
import React, { useState } from 'react';

const RenewMembership = () => {
  const [billingPeriod, setBillingPeriod] = useState('yearly');

  const toggleBillingPeriod = () => {
    setBillingPeriod(billingPeriod === 'yearly' ? 'monthly' : 'yearly');
  };

  const pricingData = [
    {
      tier: 'Starter',
      monthlyPrice: 15,
      monthlyyearlyPrice: 12,
      yearlyPrice: 144,
      features: [
        '90 Day chat history',
        'Basic widget customisation',
        'Ticketing system',
        'Ensure data security'
      ]
    },
    {
      tier: 'Professional',
      monthlyPrice: 33,
      monthlyyearlyPrice: 26,
      yearlyPrice: 312,
      features: [
        'Unlimited chat history',
        'Full widget customisation',
        'File Attachments',
        'Ticketing system',
        'Ensure data security',
        'Remove branding',
        'Advanced styling',
        'Advanced Statistics'
      ]
    },
    {
      tier: 'Business',
      monthlyPrice: 59,
      monthlyyearlyPrice: 47,
      yearlyPrice: 564,
      features: [
        'Unlimited chat history',
        'Full widget customisation',
        'File Attachments',
        'Ticketing system',
        'Ensure data security',
        'Remove branding',
        'Advanced styling',
        'Full Statistics',
        'Scheduler feature'
      ]
    }
  ];

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 col-md-2"></div>
        <div className="col-12 col-md-8">
          <div className="text-center">
            <div className="alert alert-danger">
              Your subscription has expired. To restore your account choose one
              of these subscriptions.
            </div>

            <div
              className="btn-group btn-group-toggle mt-3"
              data-toggle="buttons"
            >
              <label
                className={`btn btn-primary ${
                  billingPeriod === 'monthly' ? 'active' : ''
                }`}
                onClick={toggleBillingPeriod}
              >
                <input
                  type="radio"
                  name="options"
                  id="monthly"
                  autoComplete="off"
                  checked={billingPeriod === 'monthly'}
                  onChange={() => setBillingPeriod('monthly')}
                />{' '}
                Monthly
              </label>
              <label
                className={`btn btn-primary ${
                  billingPeriod === 'yearly' ? 'active' : ''
                }`}
                onClick={toggleBillingPeriod}
              >
                <input
                  type="radio"
                  name="options"
                  id="yearly"
                  autoComplete="off"
                  checked={billingPeriod === 'yearly'}
                  onChange={() => setBillingPeriod('yearly')}
                />{' '}
                Yearly
              </label>
            </div>
          </div>
          <div className="row mt-5">
            {pricingData.map((plan, index) => (
              <div className="col-lg-4 mb-4" key={index}>
                <div className="card">
                  <div className="card-body text-start">
                    <h3 className="card-title mb-4">{plan.tier}</h3>
                    <h4 className="card-subtitle mb-2 text-muted">
                      {billingPeriod === 'monthly'
                        ? `$${plan.monthlyPrice} / Month`
                        : `$${plan.monthlyyearlyPrice} / Month`}
                    </h4>
                    <p className="card-text" style={{ color: 'green' }}>
                      {billingPeriod === 'monthly'
                        ? `Per agent billed month to month`
                        : `$${plan.yearlyPrice} billed yearly`}
                    </p>
                    <ul className="list-unstyled">
                      {plan.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                      ))}
                    </ul>
                    <a href="#" className="btn w-100 btn-outline-primary mt-3">
                      Choose Plan
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-md-2"></div>
      </div>
    </div>
  );
};

export default RenewMembership;
