import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Message {
  id: string;
  content: string;
  type: string;
  timestamp: string;
  agentRead: boolean | null;
}

interface Customer {
  customer_id: string;
  name: string;
  email: string;
  messages: Message[];
  // Add more fields as necessary
  browser?: string;
  ip?: string;
  os?: string;
  page?: string;
  country?: string;
  region?: string;
  org?: string;
  refer?: string;
  title?: string;
  city?: string;
  history?: any;
  country_name?: string;
  active?: boolean;
  browser_count?: number;
  chat_started?: boolean;
  customer_typing_text: boolean;
  customer_typed_text: string;
}

interface TrafficState {
  customers: Customer[];
  initialDataLoaded: string[]; // To keep track of server_ids
}

const initialState: TrafficState = {
  customers: [],
  initialDataLoaded: []
};

const trafficSlice = createSlice({
  name: 'traffic',
  initialState,
  reducers: {
    upsertCustomer: (state, action: PayloadAction<Customer>) => {
      const index = state.customers.findIndex(
        c => c.customer_id === action.payload.customer_id
      );
      if (index >= 0) {
        // Update existing customer
        state.customers[index] = action.payload;
      } else {
        // Add new customer
        state.customers.push(action.payload);
      }
    },

    startChat: (
      state,
      action: PayloadAction<{
        customerId: string;
        message: string;
        ownerToken: string;
        messageId: string;
      }>
    ) => {
      const { customerId, message, ownerToken, messageId } = action.payload;

      state.customers = state.customers.map(item => {
        if (item.customer_id === customerId) {
          const newMessage: Message = {
            id: messageId, // Ensure that you generate a unique ID or use your preferred ID method
            content: message,
            type: 'agent',
            agentRead: true,
            timestamp: new Date().toISOString() // Ensure this matches the type defined in your `Message` interface
          };

          return {
            ...item,
            chat_started: true,
            typing_text: '',
            active: true,
            messages: [...(item.messages || []), newMessage]
          };
        }
        return item;
      });
    },

    endChat: (
      state,
      action: PayloadAction<{
        messageId: string;
        customerId: string;
        message: string;
        ownerToken: string;
      }>
    ) => {
      const { customerId, message, messageId, ownerToken } = action.payload;

      state.customers = state.customers.map(item => {
        if (item.customer_id === customerId) {
          const newMessage: Message = {
            id: messageId, // Ensure that you generate a unique ID or use your preferred ID method
            content: message,
            type: 'agent',
            agentRead: true,
            timestamp: new Date().toISOString() // Ensure this matches the type defined in your `Message` interface
          };

          return {
            ...item,
            chat_started: false,
            typing_text: '',
            messages: [...(item.messages || []), newMessage]
          };
        }
        return item;
      });
    },


    endChatbyAgent: (
      state,
      action: PayloadAction<{
        messageId: string;
        customerId: string;
        message: string;
        ownerToken: string;
      }>
    ) => {
      const { customerId, message, messageId, ownerToken } = action.payload;

      console.log("test");

      state.customers = state.customers.map(item => {
        if (item.customer_id === customerId) 
        {


          if(item.chat_started == true)
          {
            
            const newMessage: Message = {
              id: messageId, // Ensure that you generate a unique ID or use your preferred ID method
              content: message,
              type: 'agent',
              agentRead: true,
              timestamp: new Date().toISOString() // Ensure this matches the type defined in your `Message` interface
            };
  
            return {
              ...item,
              chat_started: false,
              typing_text: '',
              messages: [...(item.messages || []), newMessage]
            };

          }
          else return item;

          
        }
        return item;
      });
    },

    sendMessage: (
      state,
      action: PayloadAction<{
        messageId: string;
        customerId: string;
        message: string;
        ownerToken: string;
      }>
    ) => {
      const { customerId, message, messageId, ownerToken } = action.payload;

      state.customers = state.customers.map(item => {
        if (item.customer_id === customerId) {
          const newMessage: Message = {
            id: messageId, // Ensure that you generate a unique ID or use your preferred ID method
            content: message,
            type: 'agent',
            agentRead: true,
            timestamp: new Date().toISOString() // Ensure this matches the type defined in your `Message` interface
          };

          return {
            ...item,
            chat_started: true,
            typing_text: '',
            active: true,
            messages: [...(item.messages || []), newMessage]
          };
        }
        return item;
      });
    },

    // Add a message to a specific customer
    addMessageToCustomer: (
      state,
      action: PayloadAction<{ customer_id: string; message: Message }>
    ) => {
      const customer = state.customers.find(
        c => c.customer_id === action.payload.customer_id
      );
      if (customer) {
        customer.messages.push(action.payload.message);
      }
    },


    addReceivedMessageFromAnotherLocation: (
      state,
      action: PayloadAction<{ customer_id: string; message: Message }>
    ) => {


      console.log("gaaa teeeeest")

      const customer = state.customers.find(
        c => c.customer_id === action.payload.customer_id
      );
    
      if (customer) {
        const messageExists = customer.messages.some(
          m => m.id === action.payload.message.id
        );
    
        if (!messageExists) {
          customer.chat_started = true
          customer.messages.push(action.payload.message);
        
        }
      }
    },


    // Bulk update customers
    bulkUpdateCustomers: (
      state,
      action: PayloadAction<{ customers: Customer[]; server_id: string }>
    ) => {
      const { customers, server_id } = action.payload;

      // Check if data from this server_id has already been processed
      if (state.initialDataLoaded.includes(server_id)) {
        return state;
      }

      // Map the incoming customer data to match your application schema
      const updatedCustomers = customers.map((item: any) => {
        const receivedPage = item.page ?? 'n/a';

        return {
          browser: item.browser,
          ip: item.ip,
          os: item.os,
          page: receivedPage,
          country: item.country,
          name: item.name,
          email: item.email,
          customer_id: item.customer_id,
          region: item.region,
          org: item.org,
          messages: [],
          refer: item.refer,
          title: item.title,
          city: item.city,
          history: item.history,
          country_name: item.country_name,
          active: true,
          browser_count: 1,
          chat_started: item.chat_started,
          customer_typing_text: false,
          customer_typed_text: ''
        };
      });

      // Update the state with the new customer data
      return {
        ...state,
        customers: [...state.customers, ...updatedCustomers],
        initialDataLoaded: [...state.initialDataLoaded, server_id]
      };
    },

    updateCustomer: (state, action: PayloadAction<Customer>) => {
      const payload = action.payload;
      const customer_idPP = payload.customer_id;

      // Check if the customer already exists in the array
      const existingCustomerIndex = state.customers.findIndex(
        c => c.customer_id === customer_idPP
      );

      const newObj = { visited_page: payload.page, title: payload.title };

      if (existingCustomerIndex !== -1) {
        // Customer exists, update their status and page
        const updatedCustomers = [...state.customers];
        updatedCustomers[existingCustomerIndex] = {
          ...updatedCustomers[existingCustomerIndex],
          active: true,
          page: payload.page,
          title: payload.title,
          history: [...updatedCustomers[existingCustomerIndex].history, newObj]
        };

        return {
          ...state,
          customers: updatedCustomers
        };
      } else {
        // Customer doesn't exist, add them to the array
        const newCustomer = {
          browser: payload.browser,
          ip: payload.ip,
          os: payload.os,
          page: payload.page || 'n/a',
          country: payload.country,
          name: payload.name,
          email: payload.email,
          customer_id: customer_idPP,
          refer: payload.refer,
          region: payload.region,
          org: payload.org,
          title: payload.title,
          messages: [],
          city: payload.city,
          history: [newObj],
          country_name: payload.country_name,
          active: true,
          browser_count: 1,
          chat_started: payload.chat_started || false,
          customer_typing_text: false,
          customer_typed_text: ''
        };

        return {
          ...state,
          customers: [...state.customers, newCustomer]
        };
      }
    },

    removeCustomer: (state, action: PayloadAction<any>) => {
      const updatedCustomers = state.customers.map(customer => {
        if (customer.customer_id === action.payload.customer_id) {
          const newBrowserCount = (customer.browser_count || 1) - 1;

          if (newBrowserCount === 0) {
            // Customer is no longer active
            return { ...customer, active: false, browser_count: 0 };
          } else {
            // Decrement browser_count but keep the customer active
            return {
              ...customer,
              active: true,
              browser_count: newBrowserCount
            };
          }
        }
        return customer;
      });

      state.customers = updatedCustomers;
    },

    customerTypingText: (
      state,
      action: PayloadAction<{ customer_id: string; text: string }>
    ) => {
      return {
        ...state,
        customers: state.customers.map(item => {
          if (item.customer_id === action.payload.customer_id) {
            return {
              ...item,
              customer_typing_text: true,
              customer_typed_text: action.payload.text
            };
          }
          return item;
        })
      };
    },

    receivedStartChatFromCustomer: (
      state,
      action: PayloadAction<{
        customer_id: string;
        email: string;
        name: string;
      }>
    ) => {
      return {
        ...state,
        customers: state.customers.map(item => {
          if (item.customer_id === action.payload.customer_id) {
            return {
              ...item,
              chat_started: true,
              email: action.payload.email,
              name: action.payload.name
            };
          }
          return item;
        })
      };
    },

    customerEndedTheChat: (
      state,
      action: PayloadAction<{ customer_id: string }>
    ) => {
      const { customer_id } = action.payload;

      state.customers = state.customers.map(item => {
        if (item.customer_id === customer_id) {
          const newMessage: Message = {
            id: Date.now().toString(), // Ensure that you generate a unique ID or use your preferred ID method
            content: 'Customer ended the chat',
            type: 'customer',
            agentRead: false,
            timestamp: new Date().toISOString() // Ensure this matches the type defined in your `Message` interface
          };

          return {
            ...item,
            chat_started: false,
            typing_text: '',
            active: true,
            messages: [...(item.messages || []), newMessage],
            customer_typing_text: false,
            customer_typed_text: ''
          };
        }
        return item;
      });
    },

    sendTypingMessage: (
      state,
      action: PayloadAction<{ customerId: string; ownerToken: string }>
    ) => {
      // do nothing here, we just need to informthe middleware.
    },

    receivedMessageFromCustomer: (
      state,
      action: PayloadAction<{ customer_id: string; message: string }>
    ) => {
      const { customer_id, message } = action.payload;

      state.customers = state.customers.map(item => {
        if (item.customer_id === customer_id) {
          const newMessage: Message = {
            id: Date.now().toString(), // Ensure that you generate a unique ID or use your preferred ID method
            content: message,
            type: 'customer',
            agentRead: false,
            timestamp: new Date().toISOString() // Ensure this matches the type defined in your `Message` interface
          };

          return {
            ...item,
            chat_started: true,
            typing_text: '',
            active: true,
            messages: [...(item.messages || []), newMessage],
            customer_typing_text: false,
            customer_typed_text: ''
          };
        }
        return item;
      });
    }
  }
});

export const {
  upsertCustomer,
  removeCustomer,
  receivedMessageFromCustomer,
  sendTypingMessage,
  startChat,
  endChat,
  sendMessage,
  addReceivedMessageFromAnotherLocation,
  customerTypingText,
  addMessageToCustomer,
  endChatbyAgent,
  customerEndedTheChat,
  receivedStartChatFromCustomer,
  bulkUpdateCustomers,
  updateCustomer
} = trafficSlice.actions;
export default trafficSlice.reducer;
