import MainLayout from 'layouts/MainLayout';
import Starter from 'pages/pages/Starter';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import ButtonExample from 'pages/modules/components/ButtonExample';
import AccordionExample from 'pages/modules/components/AccordionExample';
import CustomersPage from 'pages/customers/Customers';
import StatisticsPage from 'pages/statistics/StatisticsPage';
import VisibilityExample from 'pages/modules/utilities/VisibilityExample';
import BasicTableExample from 'pages/modules/tables/BasicTableExample';
import GridExample from 'pages/modules/utilities/GridExample';
import ViewTicket from 'pages/tickets/ViewTicket';
import CreateNew from 'pages/apps/project-management/CreateNew';
import ProjectListView from 'pages/apps/project-management/ProjectListView';
import ProjectDetails from 'pages/apps/project-management/ProjectDetails';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import UserAccountPage from 'pages/user-account/UserAccount';
import BillingPage from 'pages/billing/BillingPage';

import Chat from 'pages/apps/chat/Chat';
import ChatHomepage from 'pages/apps/chat/ChatHomepage';
import ChatConversation from 'pages/apps/chat/ChatConversation';
import ChatBotPage from 'pages/chat-bot/ChatBotPage';

import ExtraAppsPage from 'pages/extra-apps/ExtraAppsPage';
import SettingsPage from 'pages/settings/SettingsPage';

import FaqAccordion from 'pages/faq/FaqAccordion';
import Inbox from 'pages/apps/email/Inbox';
import EmailDetail from 'pages/apps/email/EmailDetail';
import Compose from 'pages/apps/email/Compose';
import Notification from 'pages/notifications/Notifications';
import PricingColumn from 'pages/pages/pricing/PricingColumn';
import SocialProfile from 'pages/apps/social/SocialProfile';
import Settings from 'pages/apps/social/Settings';
import Feed from 'pages/apps/social/Feed';
import Error404 from 'pages/error/Error404';
import Error403 from 'pages/error/Error403';
import Error500 from 'pages/error/Error500';
import AdvanceTableExample from 'pages/modules/tables/AdvanceTableExample';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';
import SimpleSignUp from 'pages/pages/authentication/simple/SignUp';
import SimpleSignOut from 'pages/pages/authentication/simple/SignOut';
import SimpleResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleLockScreen from 'pages/pages/authentication/simple/LockScreen';
import SimpleTwoFA from 'pages/pages/authentication/simple/TwoFA';
import SimpleForgotPassword from 'pages/pages/authentication/simple/ForgotPassword';
import Showcase from 'pages/Showcase';
import VerticalSidenav from 'pages/pages/layouts/VerticalSidenav';
import DarkModeDemo from 'pages/pages/layouts/DarkModeDemo';
import SidenavCollapse from 'pages/pages/layouts/SidenavCollapse';

import App from 'App';
import Crm from 'pages/dashboard/Crm';
import LeadDetails from 'pages/apps/crm/LeadDetails';
import Analytics from 'pages/apps/crm/Analytics';

import {  lazy } from 'react';
import PhoenixLoader from 'components/common/PhoenixLoader';
import Migrations from 'pages/documentation/Migrations';
import Kanban from 'pages/apps/kanban/Kanban';
import Boards from 'pages/apps/kanban/Boards';
import CreateBoard from 'pages/apps/kanban/CreateBoard';
import WizardExample from 'pages/modules/forms/WizardExample';
import Deals from 'pages/apps/crm/Deals';
import FaqTab from 'pages/faq/FaqTab';
import Alternate from 'pages/pages/landing/Alternate';
import Calendar from 'pages/apps/calendar/Calendar';
import Timeline from 'pages/pages/Timeline';
import CalendarExample from 'pages/modules/components/CalendarExample';
import DraggableExample from 'pages/modules/components/DraggableExample';
import Widgets from 'pages/modules/Widgets';
import ComingSoon from 'pages/ComingSoon';

import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'hooks';

import ProtectedRoute from 'ProtectedRoute';

import LoginScreen from 'login/Login';
import ChatArchivesPage from 'pages/chat-archives/ChatArchivesPage';

const FontAwesomeExample = lazy(
  () => import('pages/modules/components/FontAwesomeExample')
);

const FeatherIconsExample = lazy(
  () => import('pages/modules/components/FeatherIconsExample')
);

const UniconsExample = lazy(
  () => import('pages/modules/components/UniconsExample')
);
import Color from './pages/documentation/customization/Color';
import TravelAgency from 'pages/dashboard/TravelAgency';
import TravelLanding from 'pages/apps/travel-agency/landing/Landing';
import TrafficPage from 'pages/traffic/Traffic';
import RenewMembershipOuter from 'pages/renew-membership/RenewMembershipOuter';
import MainDashboard from 'pages/dashboard/MainDashboard';
import AgentsPage from 'pages/agents/AgentsPage';
import ReportsPage from 'pages/reports/ReportsPage';
import TicketsPage from 'pages/tickets/TicketsPage';
import Tickets from 'pages/tickets/Tickets';

import InstallChatWidget from 'pages/settings/install-easychatdesk/InstallChatWidget';
import AspectDesign from 'pages/settings/aspect-design/AspectDesign';
import Language from 'pages/settings/language/Language';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/login',
        element: <LoginScreen></LoginScreen>
      },

      {
        path: '/renew-membership',
        element: <RenewMembershipOuter />
      },

      {
        path: '/',
        element: (
          <ProtectedRoute>
            <MainLayoutProvider>
              <MainLayout />
            </MainLayoutProvider>
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <MainDashboard />
          },
 

          {
            path: '/agents',
            element: (
              <ProtectedRoute>
                <AgentsPage />
              </ProtectedRoute>
            )
          },

          {
            path: '/traffic',
            element: (
              <ProtectedRoute>
                <TrafficPage />
              </ProtectedRoute>
            )
          },

          {
            path: '/reports',
            element: (
              <ProtectedRoute>
                <ReportsPage />
              </ProtectedRoute>
            )
          },


          {
            path: '/statistics',
            element: (
              <ProtectedRoute>
                <StatisticsPage />
              </ProtectedRoute>
            )
          },


          {
            path: '/customers',
            element: (
              <ProtectedRoute>
                <CustomersPage />
              </ProtectedRoute>
            )
          },


 

          {
            path: '/tickets',
            element: (
              <ProtectedRoute>
                <Tickets />
              </ProtectedRoute>
            ),


            

            children: [
              {
                index: true,
                element:  <ProtectedRoute><TicketsPage /></ProtectedRoute>
              },
     

              {
                path: ':ticketId/conversation', // Remove index: true
                element: (
                  <ProtectedRoute>
                    <ViewTicket />
                  </ProtectedRoute>
                )
              }
            ]
          },
 

          {
            path: '/chat-archives',
            element: (
              <ProtectedRoute>
                <ChatArchivesPage />
              </ProtectedRoute>
            )
          },


          {
            path: '/extra-apps',
            element: (
              <ProtectedRoute>
                <ExtraAppsPage />
              </ProtectedRoute>
            )
          },


          {
            path: '/chat-bot',
            element: (
              <ProtectedRoute>
                <ChatBotPage />
              </ProtectedRoute>
            )
          },



          {
            path: '/settings',
            element: (
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            ),
            children:
            [
              {
                index: true,
                element: <InstallChatWidget />
              },
              {
                path: 'aspect-design',
                element: <AspectDesign />
              },
              {
                path: 'language',
                element: <Language />
              }
            ]
          },


          {
            path: '/billing',
            element: (
              <ProtectedRoute>
                <BillingPage />
              </ProtectedRoute>
            )
          },


          {
            path: '/user-account',
            element: (
              <ProtectedRoute>
                <UserAccountPage />
              </ProtectedRoute>
            )
          },

          
          

          {
            path: '/apps',
            children: [
      
   
              {
                path: 'chat',
                element: <Chat />,
                children: [
                  {
                    index: true,
                    element: <ChatHomepage />
                  },
                  {
                    path: ':userId/conversation',
                    element: <ChatConversation />
                  }
                ]
              },
 
 
 
            ]
          },
  
 

 
        ]
      },
 

 
 
 
      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
