// src/store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './slices/authSlice';
import trafficReducer from './slices/trafficSlice';
import webSocketMiddleware from './webSocketMiddleware';

import thunk from 'redux-thunk'; // Import thunk middleware

// Define persist config for auth
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth'] // Only persist auth slice
};

// Combine reducers with persistReducer applied only to authReducer
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  traffic: trafficReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false // Disable serializable check if using non-serializable values
    }).concat(webSocketMiddleware)
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
