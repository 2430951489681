import { Col, Row } from 'react-bootstrap';
import EcomTotalOrdersCard from 'components/cards/EcomTotalOrdersCard';
import EcomNewCustomersCard from 'components/cards/EcomNewCustomersCard';
import EcomTopCouponsCard from 'components/cards/EcomTopCouponsCard';
import EcomPayingVsNonPayingCard from 'components/cards/EcomPayingVsNonPayingCard';
import EcomLatestReviewsTable from 'components/tables/EcomLatestReviewsTable';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';
import EcomStats from 'components/stats/EcomStats';
import { mapMarkerPoints } from 'data/mapMarkerPoints';
import EcomProjectionVsActual from 'components/modules/e-commerce/dashboard/EcomProjectionVsActual';
import EcomReturningCustomerRate from 'components/modules/e-commerce/dashboard/EcomReturningCustomerRate';
import EcomTotalSells from 'components/modules/e-commerce/dashboard/EcomTotalSells';
import EcomTopRegions from 'components/modules/e-commerce/dashboard/EcomTopRegions';
import { Card } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';

const MainDashboard = () => {

  const navigate = useNavigate();


  return (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <Col xs={12} xxl={12}>
            <div className="mb-8">
              <h2 className="mb-2">My Dashboard</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Here’s what’s going on at your business right now
              </h5>
            </div>  
          </Col>
          <Col xs={12} xxl={6}>
            <Row className="g-3">
        
   
              <Col xs={12} md={6}>
                <EcomTopCouponsCard />
              </Col>


              <Col xs={12} md={6}>



              <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Add your widget code</h5>
            <h6 className="text-body-tertiary">Do not forget to add your widget js code in your site</h6>
          </div>
        </div>


        <div className='mt-5'>
            <button onClick={() =>{
              navigate('/settings');
            }} className="btn btn-primary">Check Settings</button>
        </div>
 
 
      </Card.Body>
    </Card>


              </Col>
 
            </Row>
          </Col>
        </Row>
      </div>
 
    </>
  );
};

export default MainDashboard;
