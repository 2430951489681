import React, { createContext, useContext, useEffect, useState } from 'react';
import WebSocketService from 'services/WebsocketService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store'; // Adjust import path
import {
  upsertCustomer,
  addMessageToCustomer,
  receivedMessageFromCustomer,
  bulkUpdateCustomers,
  customerTypingText,
  receivedStartChatFromCustomer,
  removeCustomer,
  addReceivedMessageFromAnotherLocation,
  customerEndedTheChat,
  endChatbyAgent,
  updateCustomer
} from 'store/slices/trafficSlice';

import { setAcceptChats } from 'store/slices/authSlice';

import { setWebSocketService } from 'store/WebSocketServiceSingleton';

interface WebSocketContextType {
  wsService: WebSocketService | null;
  setWsService: React.Dispatch<React.SetStateAction<WebSocketService | null>>;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [wsService, setWsService] = useState<WebSocketService | null>(null);

  const sound1 = new Audio('https://cdn.easychatdesk.com/assets/ping1.mp3');
  const sound2 = new Audio('https://cdn.easychatdesk.com/assets/funny1.mp3');
  const sound3 = new Audio('https://cdn.easychatdesk.com/assets/new_customer.mp3');
  const sound4 = new Audio('https://cdn.easychatdesk.com/assets/beep1.mp3');
  

  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    //const token = localStorage.getItem('authToken')?.replace(/^"(.+)"$/, '$1') || ''; // Retrieve token

    if (token) {
      const handleMessage = (message: any) => {
        //console.log('Received from Socket: ', message);

        if (message.action == 'account_settings') 
        {
 
            if(message.payload[0].online_status == "online")
            {
              dispatch(setAcceptChats(true));
              //console.log("setting chat to online ") 
            }
              
              else
            dispatch(setAcceptChats(false));
        }
        else if (message.action == 'update_customer_bulk') 
        {
          dispatch(bulkUpdateCustomers(message.payload));
        } 
        else if (message.action == 'update_customer') 
        {
          sound4.play();
          dispatch(updateCustomer(message.payload));
        } 
        else if (message.action == 'remove_customer') 
        {
          
          dispatch(removeCustomer(message.payload));
        
        } 
        else if (message.action == 'received_start_chat_from_customer') 
        {
          sound3.play(); 
          dispatch(receivedStartChatFromCustomer(message.payload));
        } 
        else if (message.action == 'customer_types_text') 
        {
          dispatch(customerTypingText(message.payload));
        } 
        else if (message.action == 'received_message_from_customer') 
        {
          
          sound1.play();  

          const pLoad = {
            customer_id: message.payload.customer_id,
            message: message.payload.message
          };
          dispatch(receivedMessageFromCustomer(pLoad));

        } 

        else if(message.action == "agent_has_started_chat_with_customer")
          {
            const pLoad = { customer_id: message.payload.customer_id, message: {
    
              id: message.payload.messageId,
              content: message.payload.message,
              type: "agent",
              agentRead: true,
              timestamp: new Date().toISOString()
    
            }}
    
            dispatch(addReceivedMessageFromAnotherLocation(pLoad));
    
    
          }  
          else if(message.action == "agent_ending_chat")
          {
              const {owner_token, customer_id, text, messageId} = message.payload

              const pLoad = {
                ownerToken: owner_token,
                customerId: customer_id,
                message: text,
                messageId: messageId
              }

              console.log("is here")

              dispatch(endChatbyAgent(pLoad));


          }
        else if(message.action == "agent_writing_in_other_window")
          {
      
                const pLoad = { customer_id: message.payload.customer_id, message: {
      
                  id: message.payload.messageId,
                  content: message.payload.message,
                  type: "agent",
                  agentRead: true,
                  timestamp: new Date().toISOString()
      
                }}
      
      
            dispatch(addReceivedMessageFromAnotherLocation(pLoad));
          }
        else if (message.action == 'customer_ended_the_chat') 
        {
          dispatch(
            customerEndedTheChat({
              customer_id: message.payload.customer_id
            })
          );
        }

        if (message.type === 'customer') {
          dispatch(upsertCustomer(message.data));
        } else if (message.type === 'chat') {
          console.log(message);

          dispatch(
            addMessageToCustomer({
              customer_id: message.customerId,
              message: message.data
            })
          );
        }
      };

      const service = new WebSocketService(
        'wss://api.easychatdesk.com/v1/customer',
        handleMessage,
        () => {
          service.sendLoginMessage(token);
        }
      );

      setWebSocketService(service);

      setWsService(service);
      service.connect();

      return () => {
        service.disconnect();
        setWebSocketService(null);
        setWsService(null);
      };
    }
  }, []);

  return (
    <WebSocketContext.Provider value={{ wsService, setWsService }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
